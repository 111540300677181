<template>
    <nav class="flex" aria-label="Breadcrumb">
      <ol role="list" class="flex flex-grow items-center space-x-4 font-bold">
        <li>
          <div>
            <button @click="router.push({ path: '/invoice/' + invoiceStore.selected })" class="text-gray-400 hover:text-gray-700 items-center ">
              <ListBulletIcon class="h-5 w-5 inline-flex" aria-hidden="true" />
              <span class="ml-4 inline-block align-middle">Period {{ invoiceStore.selected }}</span>
            </button>
          </div>
        </li>
        <li v-for="page in pages" :key="page.name">
          <div class="flex items-center">
            <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400 inline-flex" aria-hidden="true" />
            <span class="ml-4 text-gray-500 align-middle cursor-default" :aria-current="page.current ? 'page' : undefined" v-if="page.current">{{ page.name }}</span>
            <button @click="router.push({ path: page.href })" class="ml-4 text-gray-500 hover:text-gray-700 align-middle" :aria-current="page.current ? 'page' : undefined" v-if="!page.current">{{ page.name }}</button>
          </div>
        </li>
      </ol>
    </nav>
  </template>

  <script setup>

    import { useInvoiceStore } from '@/Stores'

    import { useRouter } from 'vue-router'

    import { ChevronRightIcon, ListBulletIcon } from '@heroicons/vue/20/solid'

    const router = useRouter()

    const invoiceStore = useInvoiceStore()

    const props = defineProps({
        pages: {
            type: Array,
            required: true
        }
    })

  </script>
