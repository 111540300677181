<template>

    <div>
        <header>

            <InvoiceNav @loadInvoice="currentInvoice" />

            <InvoiceHeader />

        </header>
        <main>

            <div @click="router.push('/welcome')" class="w-1/2 mx-auto text-center cursor-pointer group">
                <h4 class="inline-flex items-center gap-x-0.5 text-2xl font-bold font-sans text-lifeworx-blue-400 pr-4">
                    <SparklesIcon class="h-7 w-7 text-lifeworx-blue-300 mr-0.5" />
                        New!
                </h4>
                <p class="mt-2 mb-4 text-sm font-bold text-lifeworx-blue-600 text-center">
                    Invoicing at LifeWorx has a new look and more features to make getting paid even easier!
                </p>
                <button class="text-lifeworx-blue-500 font-bold underline group-hover:no-underline">
                    Read about the changes
                </button>
            </div>

            <InvoiceItems :userProfile="user" :currentPeriod="currentPeriod" :selectedPeriod="state.selectedPeriodId" @save-data="saveData" />


            <InvoiceActions :userProfile="user" :currentPeriod="currentPeriod" :selectedPeriod="state.selectedPeriodId" @save-data="saveData" />

            <RecruiterCard :recruiter="user.recruiter" />

        </main>
    </div>

</template>

<script setup>

    import { onMounted, onUpdated, reactive } from 'vue'
    import { useRouter } from 'vue-router'

    import { useConfigStore, useUserStore, useInvoiceStore, useNotificationStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { SparklesIcon } from '@heroicons/vue/20/solid'

    import InvoiceActions from '@/Components/InvoiceActions.vue'

    import InvoiceNav from '@/Components/InvoiceNav.vue'
    import InvoiceHeader from '@/Components/InvoiceHeader.vue'
    import InvoiceItems from '@/Components/InvoiceItems.vue'

    import RecruiterCard from '@/Components/RecruiterCard.vue'

    const router = useRouter()

    const emit = defineEmits(['saveData', 'deleteData', 'error'])

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()
    const notificationStore = useNotificationStore()

    const config = configStore.config;
    const { user } = storeToRefs(userStore)
    const { invoices, selectedStatus } = storeToRefs(invoiceStore)
    const apiVersion = import.meta.env.VITE_API_VERSION;

    const props = defineProps({
        currentPeriod: {
            type: Number,
            required: true
        },
        period: {
            type: String
        },
        userProfile: {
            type: Object,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: true
        }
    })

    const selectedPeriod = useSelectedPeriod()
    function useSelectedPeriod() {
        let selectedPeriod = Number(props.currentPeriod);
        if (props.period) {
            return Number(props.period);
        }
        return selectedPeriod;
    }

    const state = reactive({
        selectedPeriodId: selectedPeriod,
        readOnly: props.readOnly
    })

    function currentInvoice(value) {
        state.selectedPeriodId = value;
        invoiceStore.selectedPeriod = value
        if (['open', 'declined'].includes(selectedStatus.value)) {
            state.readOnly = true;
        } else {
            state.readOnly = false;
        }
    }

    function saveData(data) {
        emit('saveData', data);
    }

    onMounted(() => {
        if (props.period && invoiceStore.isPeriodExist(props.period) === false) {
            notificationStore.add('Error 404', 'error', 'Invoice not found')
            router.push({ path: '/'});
        }
        currentInvoice(useSelectedPeriod())
    })

    onUpdated(() => {
        invoiceStore.loadInvoices()
        currentInvoice(useSelectedPeriod())
    })

</script>
