
import { ref, computed, watch } from 'vue'

import { useRoute } from 'vue-router'

import { defineStore, acceptHMRUpdate, storeToRefs } from 'pinia'

import { useAuthStore, useConfigStore, useNotificationStore, useUserStore } from '@/Stores'

import { util } from '@/Helpers'
import { data } from 'autoprefixer'

export const useInvoiceStore = defineStore('invoice', () => {

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)
    const authStore = useAuthStore()
    const { loading } = storeToRefs(authStore)
    const notificationStore = useNotificationStore()
    const { showOverlayConfirm, overlayConfirmData, overlayConfirmed, overlayConfirmedUnwatch, showNotification, notificationMessage } = storeToRefs(notificationStore)

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const route = useRoute()

    const selectedPeriod = ref(null)
    const invoices = ref({})
    const dataFullyLoaded = {
        next: false,
        prev: false
    }
    let indexRequestLoading = false;


    //
    // API
    //

    async function index(target_period_id, direction) {
        const itemPerPage = import.meta.env.VITE_INVOICE_ITEM_PER_PAGE;
        if (indexRequestLoading) {
            return;
        }

        const params = {
            params: {
                period_id : target_period_id,
                direction : direction
            }
        };
        authStore.auth.loading = true
        indexRequestLoading = true;
        return await axios.get('/invoices', params).then(response => {
            authStore.auth.loading = false

            const additionalInvoices = util.convertArrayToObject(response.data, 'period_id');
            invoices.value = Object.assign(invoices.value, additionalInvoices);

            if (response.data.length < itemPerPage) {
                dataFullyLoaded[direction] = true;
            }

            indexRequestLoading = false;
        }).catch((err) => {
            authStore.auth.loading = false
            indexRequestLoading = false;
            authStore.handleError(err)
        });

    }

    async function show(invoice_id) {

        authStore.auth.loading = true

        return await new Promise((resolve, reject) => {
            axios.get('/invoices/' + invoice_id).then(response => {
                authStore.auth.loading = false
                resolve(response)
            }).catch((err) => {
                authStore.auth.loading = false
                authStore.handleError(err)
                reject({ "error": error })
            });
        });
    }

    async function store() {

        authStore.auth.loading = true

        return await new Promise((resolve, reject) => {
            axios.post('/invoices/')
            .then(function (response) {
                // Handle redirect here
                if (response.status === 201) {
                    const redirectUrl = response.headers.location;
                    return axios.get(redirectUrl);
                }
            }).then(function (response) {
                authStore.auth.loading = false
                resolve(response)
            }).catch(function (error) {
                authStore.auth.loading = false
                reject({ "error": error })
            })
        });

    }

    async function update(invoice_id, payload) {

        authStore.auth.loading = true

        return await new Promise((resolve, reject) => {
            axios.patch('/invoices/' + invoice_id, payload)
            .then(function (response) {
                authStore.auth.loading = false
                resolve(response)
            }).catch(function (error) {
                authStore.auth.loading = false
                reject({ "error": error })
            })
        });

    }

    async function destroy(invoice_id) {

        authStore.auth.loading = true

        return await axios.delete('/invoices/' + invoice_id).then(response => {
            authStore.auth.loading = false
        }).catch((err) => {
            authStore.auth.loading = true
            authStore.handleError(err)
        });

    }


    //
    // API Actions
    //

    // Attempt to copy the last submitted invoice data
    // NB: the `force` parameter skips the conflict check
    async function copyLastInvoiceData(force = false) {

        authStore.auth.loading = true

        if (force == true) {
            return await new Promise((resolve, reject) => {
                axios.post('/invoices/import-last-submitted-invoice')
                .then(function (response) {
                    authStore.auth.loading = false
                    resolve(response)
                }).catch(function (error) {
                    authStore.auth.loading = false
                    reject({ "error": error })
                })
            });
        }

        return await new Promise((resolve, reject) => {
            axios.get('/invoices/import-last-invoice-conflict')
            .then(function (response) {
                if (response.data.message.length == 0) {
                    return axios.post('/invoices/import-last-submitted-invoice');
                } else {
                    authStore.auth.loading = false
                    resolve(response)
                }
            }).then(function (response) {
                authStore.auth.loading = false
                resolve(response)
            }).catch(function (error) {
                authStore.auth.loading = false
                reject({ "error": error })
            })
        });

    }


    //
    // Frontend Logic
    //

    // Attempt to copy the last submitted invoice using the API action
    function copyLastInvoice() {

        copyLastInvoiceData().then((response) => {

            authStore.auth.loading = false

            if (response.status >= 200 && response.status < 400) {

                if (response.data.message && Object.keys(response.data.message).length > 0) {

                    // There are warning messages, let's show a confirmation dialog
                    overlayConfirmData.value = response.data
                    showOverlayConfirm.value = true

                    overlayConfirmedUnwatch.value = watch(
                        () => overlayConfirmed.value,
                        async () => {
                            copyLastInvoiceData(true).then((response) => {
                                authStore.auth.loading = false
                                invoices.value[selectedPeriod.value] = response.data
                                notificationStore.add('Shifts copied successfully')
                                notificationStore.overlayConfirmedUnwatch()
                                overlayConfirmed.value = false
                            }).catch((err) => {
                                authStore.auth.loading = false
                                authStore.handleError(err)
                            })
                        }
                    )

                } else {

                    // Shifts were successfully copied without a warning
                    invoices.value[selectedPeriod.value] = response.data
                    notificationStore.add('Shifts copied successfully')

                }

            }

        }).catch((err) => {
            authStore.handleError(err.error)
        })

    }

    function isPeriodExist(period_id)
    {
        return invoices.value[period_id] !== undefined;
    }

    // Always returns current invoice period ID from config
    const current = computed(() => {
        return config.value.currentPeriod.id;
    })

    // Returns the invoice period ID requested by user actions or falls back to current from config
    const selected = computed(() => {
        if (selectedPeriod.value) {
            return Number(selectedPeriod.value);
        }
        return current.value;
    })

    // Returns the status string of the selected invoice
    const selectedStatus = computed(() => {

        if (invoices.value[selectedPeriod.value]?.id) {
            return invoices.value[selectedPeriod.value].status;
        } else if (invoices.value[selectedPeriod.value] && !invoices.value[selectedPeriod.value].id && (selectedPeriod.value == current.value)) {
            return 'open';
        }

        return 'closed';

    })

    // Returns the oldest user invoice period ID available
    const oldest = computed(() => {
        return Number(Object.keys(invoices.value)[0]);
    })

    // Returns the preceding invoice period ID or null if selected invoice is the oldest
    const previous = computed(() => {
        let prevPeriodId = null
        if (Object.keys(invoices.value).length > 0) {
            const curPeriodIndex = Object.keys(invoices.value).findIndex(x => x == selected.value);
            if (curPeriodIndex >= 1) {
                prevPeriodId = Object.values(invoices.value)[ curPeriodIndex-1 ].period_id;
            }
        }
        return prevPeriodId;
    })

    // Returns the following invoice period ID or null if selected invoice is the newest
    const next = computed(() => {
        let nextPeriodId = null
        if (Object.keys(invoices.value).length > 0) {
            const curPeriodIndex = Object.keys(invoices.value).findIndex(x => x == selected.value);
            if (curPeriodIndex+1 < Object.keys(invoices.value).length) {
                nextPeriodId = Object.values(invoices.value)[ curPeriodIndex+1 ].period_id
            }
        }
        return nextPeriodId;
    })

    // Returns true if the current entry period is closing within the next 12 hours
    const closingSoon = computed(() => {
        if (invoices.value[selected.value]) {
            let end_date = new Date(
                invoices.value[selected.value].end_date.substr(0, 4),
                Number(invoices.value[selected.value].end_date.substr(5, 2))-1,
                Number(invoices.value[selected.value].end_date.substr(8, 2))
            );
            end_date.setDate(end_date.getDate() + 2);
            if (new Date >= end_date) {
                return true
            }
        }
        return false;
    })

    // Returns a human-readable date range string for the currently selected period/invoice
    const friendlyDateRange = computed(() => {
        let readableString = ''
        if (invoices.value[selected.value]) {
            let start_date = new Date(
                invoices.value[selected.value].start_date.substr(0, 4),
                Number(invoices.value[selected.value].start_date.substr(5, 2))-1,
                Number(invoices.value[selected.value].start_date.substr(8, 2))
            );
            let end_date = new Date(
                invoices.value[selected.value].end_date.substr(0, 4),
                Number(invoices.value[selected.value].end_date.substr(5, 2))-1,
                Number(invoices.value[selected.value].end_date.substr(8, 2))
            );
            readableString = (start_date.getMonth()+1) + '/' + start_date.getDate() + ' - ' + (end_date.getMonth()+1) + '/' + end_date.getDate()
        }
        return readableString;
    })

    // Check for missing care notes on current open invoice
    const careNotesMissing = computed(() => {
        let missing = false;
        if (selected.value == current.value) {
            invoices.value[selected.value].shifts.forEach(shift => {
                if (import.meta.env.VITE_API_VERSION === 'v1') {
                    if ((user.value.clients[shift.client_id].careNotes == true && !shift.care_notes) || (user.value.clients[shift.client_id].extendedCareNotes == true && (!shift.care_notes || !shift.care_notes.extended))) {
                        missing = true;
                    }
                } else {
                    if ((user.value.clients[shift.client_id].careNotes == true && !shift.carenote_data) || (user.value.clients[shift.client_id].extendedCareNotes == true && !shift.carenote_data)) {
                        missing = true;
                    }
                }
            });
        }
        return missing;
    })


    // Returns a human-readable date range string for the currently selected period/invoice
    const friendlyFullDateRange = computed(() => {
        let readableString = ''
        if (invoices.value[selected.value]) {
            let start_date = new Date(
                invoices.value[selected.value].start_date.substr(0, 4),
                Number(invoices.value[selected.value].start_date.substr(5, 2))-1,
                Number(invoices.value[selected.value].start_date.substr(8, 2))
            );
            let end_date = new Date(
                invoices.value[selected.value].end_date.substr(0, 4),
                Number(invoices.value[selected.value].end_date.substr(5, 2))-1,
                Number(invoices.value[selected.value].end_date.substr(8, 2))
            );
            readableString = (start_date.getMonth()+1) + '/' + start_date.getDate() + '/' + start_date.getFullYear() + ' - ' + (end_date.getMonth()+1) + '/' + end_date.getDate() + '/' + end_date.getFullYear()
        }
        return readableString;
    })

    const storeData = (rawInvoices) => {
        if (rawInvoices.length > 0) {
            const itemPerPage = import.meta.env.VITE_INVOICE_ITEM_PER_PAGE;
            invoices.value = util.convertArrayToObject(rawInvoices, 'period_id');

            if (rawInvoices[rawInvoices.length - 1].period_id == current.value) {
                dataFullyLoaded.next = true;
            }

            const match = window.location.href.match(/\/invoice\/(\d+)/);
            const period_id = match ? parseInt(match[1]) : null;
            if (period_id) {
                const keys = Object.keys(invoices.value).map(key => parseInt(key));
                const currentSelectedIndex = keys.indexOf(period_id);
                if (currentSelectedIndex < (itemPerPage - 1)) {
                    dataFullyLoaded.prev = true;
                }
            }
        }
    }

    const loadInvoices = () => {
        if (dataFullyLoaded.next === true && dataFullyLoaded.prev === true) {
            return;
        }

        const keys = Object.keys(invoices.value).map(key => parseInt(key));
        const currentSelectedIndex = keys.indexOf(selected.value);

        if (dataFullyLoaded.prev === false && currentSelectedIndex < 10) {
            index(keys[0], 'prev');
        }

        if (dataFullyLoaded.next === false && (keys.length - currentSelectedIndex) < 10) {
            let lastNoneEmptyindex = keys.length - 1;

            if (invoices.value[keys[lastNoneEmptyindex]].id === null) {
                lastNoneEmptyindex -= 1;
            }
            index(keys[lastNoneEmptyindex], 'next');
        }
    }

    function sortShifts() {
        if (invoices.value[selected.value] && invoices.value[selected.value].shifts && invoices.value[selected.value].shifts.length > 0) {
            invoices.value[selected.value].shifts.sort(util.shiftCompare)
        }
    }

    // Keeps shifts in chronological order
    // watch(
    //     () => invoices.value,
    //     async () => {
    //         if (invoices.value[selected.value] && invoices.value[selected.value].shifts && invoices.value[selected.value].shifts.length > 0) {
    //             invoices.value[selected.value].shifts.sort(util.shiftCompare)
    //         }
    //     },
    //     {
    //         deep: true,
    //         immediate: true
    //     }
    // )

    watch(
        () => route.fullPath, (selected) => {
            if (route.params.period) {
                selectedPeriod.value = route.params.period
            } else {
                selectedPeriod.value = current
            }
        }
    ), { immediate: true }


    return {
        invoices, current, oldest, selected, selectedPeriod, selectedStatus, previous, next, careNotesMissing,
        friendlyDateRange, friendlyFullDateRange, closingSoon,
        copyLastInvoice, copyLastInvoiceData,
        index, show, store, update, destroy,
        isPeriodExist, storeData, loadInvoices, sortShifts
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useInvoiceStore, import.meta.hot))
}
