    import { reactive } from 'vue'

    import { useAuthStore, useNotificationStore } from '@/Stores'

    import { defineStore, acceptHMRUpdate } from 'pinia'

    import { useRouter } from 'vue-router'

    export const useUserStore = defineStore('user', () => {

        const router = useRouter()

        const authStore = useAuthStore()
        const notificationStore = useNotificationStore()

        const user = reactive({
            profile: {
                id: null,
                profile_id: null,
                firstName: null,
                lastName: null,
                email: null,
                username: null,
                address: {},
                inv_reminder: null,
                invoice_pdf_setting: {}
            },
            recruiter: {},
            clients: [],
            rates: [],
        })


        //
        // API
        //

        async function update(payload) {

            authStore.auth.loading = true

            return await new Promise((resolve, reject) => {
                axios.patch('/users', payload)
                .then(function (response) {
                    if (response.status === 303) {
                        const redirectUrl = response.headers.location;
                        return axios.get(redirectUrl).then(function (response) {
                            authStore.auth.loading = false
                            let data = response.data
                            resolve(data)
                        });
                    } else {
                        authStore.auth.loading = false
                        let data = response.data
                        resolve(data)
                    }
                }).catch(function (error) {
                    authStore.auth.loading = false
                    reject({ "error": error })
                })
            });

        }

        async function saveSettings(payload) {
            update(payload).then((response) => {
                user.profile = response
                notificationStore.add('Settings saved successfully!')
                router.push({ path: '/' })
            }).catch((err) => {
                authStore.handleError(err.error)
            })
        }


        //
        // API Actions
        //

        // Toggle the current (known) invoice reminder status, returning false if there is an issue
        async function toggleInvoiceReminders() {

            let reminderStatus = parseInt(user.profile.inv_reminder)
            reminderStatus = 1 - reminderStatus // Flips 1 <-> 0

            update({ inv_reminder: reminderStatus }).then((response) => {
                if (response.inv_reminder == reminderStatus) {
                    user.inv_reminder = reminderStatus
                    notificationStore.add('Reminders ' + (user.inv_reminder ? 'enabled' : 'disabled'))
                    return true;
                }
                return false;

            }).catch((err) => {

                authStore.handleError(err.error)

            })
        }

        return { user, toggleInvoiceReminders, update, saveSettings }

    })

    if (import.meta.hot) {
        import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
    }
