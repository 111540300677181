<template>

    <div class="my-4">

        <div class="sm:max-w-full md:w-10/12 mx-auto grid gap-2 my-6 rounded-md outline-2 -outline-offset-2 outline-dashed outline-lifeworx-red-500 bg-lifeworx-red-50 p-8 font-semibold" v-if="invoices && invoices[invoiceStore.selected] && selectedStatus == 'declined'">

            <h3 class="text-lifeworx-red-500 text-md">
                This invoice was declined. Please make any necessary corrections before resubmitting:
            </h3>

            <p class="text-black text-md">
                {{ invoices[invoiceStore.selected].declined_reason }}
            </p>

        </div>

        <section>

            <div class="sm:max-w-full md:w-2/3 mx-auto pt-2" v-if="invoices && invoices[invoiceStore.selected] && invoices[invoiceStore.selected].shifts && invoices[invoiceStore.selected].shifts.length > 0">

                <div class="grid gap-4 grid-cols-2 w-full uppercase font-bold">
                    <h4 class="text-lifeworx-blue-800">Shifts ({{ invoices[ invoiceStore.selected ].shifts.length }})</h4>
                    <h4 class="text-right text-lifeworx-green-500">
                        {{ util.currency(util.sumShifts(invoices[ invoiceStore.selected ].shifts, user.rates)) }}
                    </h4>
                </div>

                <div class="w-full font-semibold py-4" v-for="(shifts, client_id) in shiftsByClient">

                    <h4 class="capitalize font-bold text-lg text-gray-400">
                        {{ user.clients[client_id].first_name }} {{ user.clients[client_id].last_name }}
                    </h4>

                    <div class="grid gap-1">

                        <div v-for="(shift, index) in shifts" class="flex flex-row my-2">

                            <div class="basis-10/12">

                                <h4 class="text-lifeworx-blue-800 hidden md:block">
                                    {{  util.makeFriendlyDate(shift.start, config) }} —<br>
                                    {{  util.makeFriendlyDate(shift.end, config) }}
                                </h4>
                                <h4 class="text-lifeworx-blue-800 md:hidden">
                                    {{ util.makeFriendlyShortDate(shift.start, config) }} —<br>
                                    {{ util.makeFriendlyShortDate(shift.end, config) }}
                                </h4>

                                <h4 class="text-lifeworx-green-500 font-bold">
                                    {{ util.currency(util.shiftTotal(shift.start, shift.end, user.rates[shift.rate_id].amount)) }}
                                    <span class="text-gray-400">
                                        ({{ getShiftRateDisplayName(shift, user.rates) }})
                                    </span>
                                </h4>

                                <h4 v-if="(selectedPeriod == currentPeriod) && (user.clients[client_id].careNotes == true && !shift.carenote_data) || (user.clients[client_id].extendedCareNotes == true && !shift.carenote_data)" class="text-lifeworx-red-500 font-bold">
                                    Missing required care notes
                                </h4>

                            </div>

                            <div v-if="readOnly == false" class="basis-2/12 flex justify-end items-center">
                                <div class="w-16 h-16 aspect-square border-2 border-lifeworx-blue-500 text-lifeworx-blue-500 rounded hover:bg-lifeworx-blue-500 hover:text-white items-center justify-center align-middle flex cursor-pointer" @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-shift/' + shift.id })" >
                                    <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>



            </div>

            <div v-if="invoices && invoices[invoiceStore.selected] && invoices[invoiceStore.selected].shifts && invoices[invoiceStore.selected].shifts.length > 0 && (selectedPeriod == currentPeriod) && (readOnly == false)" class="sm:max-w-full md:w-1/2 mx-auto grid gap-4 my-4">

                <LwxButton @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-shift' })" variant="secondary">
                    Add New Shift
                </LwxButton>

                <LwxButton @click="invoiceStore.copyLastInvoice()" variant="secondary">
                    Copy Last Invoice
                </LwxButton>

            </div>

            <div class="sm:max-w-full md:w-2/3 mx-auto pt-2" v-if="invoices && invoices[invoiceStore.selected] && invoices[invoiceStore.selected].shifts && invoices[invoiceStore.selected].shifts.length > 0">

                <div class="grid gap-4 grid-cols-4 w-full font-bold py-2 md:py-4 text-sm md:text-base">
                    <h4 class="col-span-3 text-gray-400">Workers' Comp / Insurance ({{ config.deduction }}%)</h4>
                    <h4 class="col-span-1 text-right text-lifeworx-blue-800">
                        ({{ util.currency(util.shiftsDeductions(invoices[ invoiceStore.selected ].shifts, config, user.rates)) }})
                    </h4>
                </div>

                <LwxHr class="my-6 md:my-4" v-if="(invoices[invoiceStore.selected].trips && invoices[invoiceStore.selected].trips.length > 0) || (invoices[invoiceStore.selected].expenses && invoices[invoiceStore.selected].expenses.length > 0) || invoices[invoiceStore.selected].notes && invoices[invoiceStore.selected].notes.length > 0" />

            </div>

        </section>

        <section>

            <div class="sm:max-w-full md:w-2/3 mx-auto pt-2" v-if="invoices && invoices[invoiceStore.selected] && invoices[invoiceStore.selected].trips && invoices[invoiceStore.selected].trips.length > 0">

                <div class="grid gap-4 grid-cols-2 w-full uppercase font-bold">
                    <h4 class="text-lifeworx-blue-800">Mileage</h4>
                    <h4 class="text-right text-lifeworx-green-500">
                        {{ util.currency(util.sumMileage(invoices[ invoiceStore.selected ].trips, config)) }}
                    </h4>
                </div>

                <div class="flex flex-row w-full font-semibold py-2" v-for="(trip, index) in invoices[ invoiceStore.selected ].trips">
                    <div class="basis-10/12">
                        <h4 class="capitalize font-bold text-gray-400">
                            {{ user.clients[trip.client_id].first_name }} {{ user.clients[trip.client_id].last_name }}
                        </h4>

                        <p class="text-lifeworx-blue-800 prose">
                            <span v-html="util.nl2br(trip.memo)"></span>
                        </p>

                        <h4 class="text-lifeworx-green-500 font-bold">

                            {{ util.currency(util.tripTotal(trip, config)) }}
                            <span class="text-gray-400">
                                ({{ trip.distance }} miles
                                @ {{ util.currency(config.mileageRate / 100) }}/mi)
                            </span>
                        </h4>
                    </div>
                    <div v-if="readOnly == false" class="basis-2/12 flex justify-end items-center pt-2">
                        <div @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-trip/' + trip.id })"  class="w-16 h-16 aspect-square border-2 border-lifeworx-blue-500 text-lifeworx-blue-500 rounded hover:bg-lifeworx-blue-500 hover:text-white items-center justify-center align-middle flex cursor-pointer">
                            <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                    </div>
                </div>

                <LwxHr class="my-6 md:my-4" />

            </div>

        </section>

        <section>

            <div class="sm:max-w-full md:w-2/3 mx-auto pt-2" v-if="invoices && invoices[invoiceStore.selected] && invoices[invoiceStore.selected].expenses && invoices[invoiceStore.selected].expenses.length > 0">

                <div class="grid gap-4 grid-cols-2 w-full uppercase font-bold">
                    <h4 class="text-lifeworx-blue-800">Expenses</h4>
                    <h4 class="text-right text-lifeworx-green-500">
                        {{ util.currency(util.sumExpenses(invoices[ invoiceStore.selected ].expenses)) }}
                    </h4>
                </div>

                <div class="flex flex-row w-full font-semibold py-2" v-for="(expense, index) in invoices[ invoiceStore.selected ].expenses">
                    <div class="basis-10/12">
                        <h4 class="capitalize font-bold text-gray-400">
                            {{ user.clients[expense.client_id].first_name }} {{ user.clients[expense.client_id].last_name }}
                        </h4>

                        <p class="text-lifeworx-blue-800 prose">
                            <span v-html="util.nl2br(expense.memo)"></span>
                        </p>

                        <h4 class="text-lifeworx-green-500 font-bold">
                            {{ util.currency(expense.amount) }}
                        </h4>
                    </div>
                    <div v-if="readOnly == false" class="basis-2/12 flex justify-end items-center pt-2">
                        <div @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-expense/' + expense.id })"  class="w-16 h-16 aspect-square border-2 border-lifeworx-blue-500 text-lifeworx-blue-500 rounded hover:bg-lifeworx-blue-500 hover:text-white items-center justify-center align-middle flex cursor-pointer">
                            <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                    </div>

                </div>

                <LwxHr v-if="invoices[invoiceStore.selected].notes" class="my-6 md:my-4" />

            </div>

        </section>

        <section>

            <div class="sm:max-w-full md:w-2/3 mx-auto pt-2" v-if="invoices && invoices[invoiceStore.selected] && invoices[invoiceStore.selected].notes && invoices[invoiceStore.selected].notes.length > 0">

                <div class="w-full uppercase font-bold">
                    <h4 class="text-lifeworx-blue-800">Notes</h4>
                </div>

                <div class="flex flex-row w-full">

                    <div class="flex pt-2" :class="{ 'basis-9/12': !readOnly, 'basis-11/12': readOnly }">
                        <p class="text-lifeworx-blue-800 text-sm prose">
                            <span v-html="util.nl2br(invoices[invoiceStore.selected].notes)"></span>
                        </p>
                    </div>

                    <div v-if="readOnly == false" class="basis-3/12 flex justify-end items-center pt-2">
                        <div @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-notes' })"  class="w-16 h-16 aspect-square border-2 border-lifeworx-blue-500 text-lifeworx-blue-500 rounded hover:bg-lifeworx-blue-500 hover:text-white items-center justify-center align-middle flex cursor-pointer">
                            <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                    </div>

                </div>

            </div>

        </section>

    </div>

</template>

<script setup>

    import { reactive, watch, computed, ref, onUpdated } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import { util } from '@/Helpers'

    import { useConfigStore, useUserStore, useInvoiceStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'

    import { PencilSquareIcon } from '@heroicons/vue/20/solid'

    const route = useRoute()
    const router = useRouter()

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()

    const config = configStore.config
    const { user } = storeToRefs(userStore)
    const { invoices, selectedStatus } = storeToRefs(invoiceStore)

    const emit = defineEmits(['saveData'])

    const props = defineProps({
        userProfile: {
            type: Object,
        },
        selectedPeriod: {
        },
        currentPeriod: {
        }
    })

    const state = reactive({
        selectedPeriodId: props.selectedPeriod
    })

    const readOnly = ref(true)

    onUpdated(() => {
        readOnly.value = !['open', 'declined'].includes(selectedStatus.value)
    })

    function getShiftRateDisplayName(shift, rates) {
        let text = util.friendlyShiftLength(shift.start, shift.end);
        if (rates[shift.rate_id].period !== 24) {
            text += ' @ ' + util.currency(rates[shift.rate_id].amount) + '/hr';
        } else {
            text += ' @ ' + util.currency(rates[shift.rate_id].amount*24) + '/24hr shift';
        }
        return text;
    }

    // Group shifts by client
    const shiftsByClient = computed(() => {
        if (invoices.value && invoices.value[invoiceStore.selected] && invoices.value[invoiceStore.selected].shifts && invoices.value[invoiceStore.selected].shifts.length > 0) {

            return invoices.value[invoiceStore.selected].shifts.reduce((groups, item) => {
                const group = (groups[item.client_id] || []);
                group.push(item);
                groups[item.client_id] = group;
                return groups;
            }, {});

        }
    })

    watch(
        () => route.fullPath,
        async () => {
            if (route.params.period) {
                state.selectedPeriodId = route.params.period;
            } else {
                state.selectedPeriodId = props.currentPeriod;
            }
        }
    )

</script>
