import { ref } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

export const useConfigStore = defineStore('config', () => {

    const config = ref({
        currentPeriod: {
            id: null
        },
        invoice_pdf_theme: {
            'fonts': [],
            'themes': [],
        },
    })

    return { config }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
