<template>

    <main>

        <div class="sm:max-w-full md:w-10/12 mx-auto grid gap-2 my-6">

            <header>

                <p class="md:mt-8 mb-2 text-5xl font-serif font-bold text-lifeworx-blue-700">
                    Welcome
                </p>

                <p class="pl-2 text-xl font-serif font-semibold text-lifeworx-blue-700">
                    to the new <a href="https://lifeworx.com" class="font-serif hover:underline text-lifeworx-blue-500" target="_blank">LifeWorx</a> Caregiver Portal!
                </p>

                <p class="mt-4 text-lg prose">
                    Invoicing at LifeWorx has a new look and more features to make getting paid even easier! Please take a moment to read through this page before logging in to the new system for the first time.
                </p>

            </header>

            <LwxHr class="mt-6 md:mt-8 mb-6" />

            <section class="grid gap-8 md:mx-20">

                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide">
                        Signing In
                    </h3>
                    <p class="my-2 max-w-md">
                        Use your existing username/email and password to sign in every week. Contact your candidate relations associate directly to reset your password.
                    </p>
                </div>


                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide md:-ml-20">
                        <span class="inline-flex items-center gap-x-0.5 rounded-md bg-lifeworx-blue-100 px-2 py-1 mr-2 text-xs font-bold font-sans text-lifeworx-blue-600 ring-1 ring-inset ring-lifeworx-blue-600/20 cursor-default select-none">
                            <SparklesIcon class="h-4 w-4 text-lifeworx-blue-500 pr-0.5" />
                            New!
                        </span>
                        Invoicing Reminders
                    </h3>
                    <p class="my-2 max-w-md">
                        You can enable or disable invoicing reminder text messages for each week after signing in.
                    </p>
                </div>


                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide md:-ml-20">
                        <span class="inline-flex items-center gap-x-0.5 rounded-md bg-yellow-100 px-2 py-1 mr-2 text-xs font-bold font-sans text-yellow-600 ring-1 ring-inset ring-yellow-600/20 cursor-default select-none">
                            <ExclamationTriangleIcon class="h-4 w-4 text-yellow-500 pr-0.5" />
                            Attn!
                        </span>
                        Shift Import &amp; Entry
                    </h3>
                    <p class="my-2 max-w-md">
                        If you only have one active client or rate, they will be selected automatically.
                    </p>
                    <p class="my-2 max-w-md">
                        Enter your shifts individually or copy them from a previous period. If you are unable to copy your previous shifts when using the new system for the first time, try again the following week.
                    </p>
                    <p class="my-2 max-w-md">
                        <b>Shifts that last longer than 24 hours must now be broken up into multiple shifts.</b>
                    </p>
                    <p class="my-2 max-w-md">
                        To delete a shift, press the edit button and click the delete button at the bottom of the screen.
                    </p>
                </div>


                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide md:-ml-20">
                        <span class="inline-flex items-center gap-x-0.5 rounded-md bg-lifeworx-blue-100 px-2 py-1 mr-2 text-xs font-bold font-sans text-lifeworx-blue-600 ring-1 ring-inset ring-lifeworx-blue-600/20 cursor-default select-none">
                            <SparklesIcon class="h-4 w-4 text-lifeworx-blue-500 pr-0.5" />
                            New!
                        </span>
                        Care Notes
                    </h3>
                    <p class="my-2 max-w-md">
                        You can now enter care notes for your long-term or health care clients while invoicing! If one or more of your clients requires care notes, you'll be required to enter those details after entering the shift start and end times. Your submission signature will be used for certification.
                    </p>
                </div>


                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide">
                        Trips, Expenses, and Notes
                    </h3>
                    <p class="my-2 max-w-md">
                        Add any billable trips or expenses, if needed. Enter any relevant notes for accounting in the notes section. If you don't see a client you worked for that week listed, you can enter the shifts there or contact your candidate relations associate for assistance. If you need to invoice for a day from a previous week, you should enter this into the notes as well.
                    </p>
                </div>


                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide md:-ml-20">
                        <span class="inline-flex items-center gap-x-0.5 rounded-md bg-lifeworx-blue-100 px-2 py-1 mr-2 text-xs font-bold font-sans text-lifeworx-blue-600 ring-1 ring-inset ring-lifeworx-blue-600/20 cursor-default select-none">
                            <SparklesIcon class="h-4 w-4 text-lifeworx-blue-500 pr-0.5" />
                            New!
                        </span>
                        Submitting Your Invoice
                    </h3>
                    <p class="my-2 max-w-md">
                        If your invoice for the week is complete, you can submit and certify with your signature. Please draw your signature with your finger on mobile or use the mouse on desktop. Your invoice will be locked after submission.
                    </p>
                </div>


                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide md:-ml-20">
                        <span class="inline-flex items-center gap-x-0.5 rounded-md bg-yellow-100 px-2 py-1 mr-2 text-xs font-bold font-sans text-yellow-600 ring-1 ring-inset ring-yellow-600/20 cursor-default select-none">
                            <ExclamationTriangleIcon class="h-4 w-4 text-yellow-500 pr-0.5" />
                            Attn!
                        </span>
                        Declined Invoices
                    </h3>
                    <p class="my-2 max-w-md">
                        If your invoice is declined by accounting, you will have the opportunity to update it with corrected information. You may see a message from accounting with additional instructions. Make any necessary changes and submit/certify your invoice again.
                    </p>
                </div>


                <div>
                    <h3 class="text-xl font-bold font-serif text-lifeworx-blue-700 tracking-wide md:-ml-20">
                        <span class="inline-flex items-center gap-x-0.5 rounded-md bg-lifeworx-blue-100 px-2 py-1 mr-2 text-xs font-bold font-sans text-lifeworx-blue-600 ring-1 ring-inset ring-lifeworx-blue-600/20 cursor-default select-none">
                            <SparklesIcon class="h-4 w-4 text-lifeworx-blue-500 pr-0.5" />
                            New!
                        </span>
                        Contact Card
                    </h3>
                    <p class="my-2 max-w-md">
                        There is now convenient contact information for your LifeWorx candidate relations associate! Call or text them with the buttons on the contact card at the bottom of every invoice.
                    </p>
                </div>



            </section>

            <LwxHr class="mt-6 md:mt-8 mb-6" />

            <section>

                <div class="mt-3 md:w-1/2 mx-auto grid gap-2">

                    <p class="col-span-2 text-2xl font-serif font-bold text-lifeworx-blue-700 text-center">
                        Ready to get started?
                    </p>

                    <div class="col-span-2 mt-2">
                        <LwxButton @click="router.push({ path: '/login' })">
                            {{ auth.authenticated ? 'Back to Invoicing' : 'Sign In' }}
                        </LwxButton>
                    </div>

                </div>

            </section>

        </div>

    </main>

</template>

<script setup>

    import { useRouter } from 'vue-router'

    import '@/Helpers'

    import { useAuthStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'

    import { ExclamationTriangleIcon, SparklesIcon } from '@heroicons/vue/20/solid'

    const router = useRouter()
    const authStore = useAuthStore()
    const { auth } = storeToRefs(authStore)

</script>
