<template>

    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 top-20 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div class="flex w-full flex-col items-center space-y-2 sm:items-center md:items-end">

            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <template v-for="(notification, index) in activeNotifications">
                <transition enter-active-class="transform ease-out duration-300 transition"
                    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:-translate-y-2"
                    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                    leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <div v-if="notification.show == true && notification.expires > new Date()" class="pointer-events-auto cursor-default w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5" :class="notification.variant == 'error' ? 'bg-lifeworx-red-600' : 'bg-lifeworx-blue-800'">
                        <div class="p-4">
                            <div class="flex items-start pt-0.5">
                                <div class="flex-shrink-0">
                                    <CheckCircleIcon v-if="notification.variant == 'message'" class="h-6 w-6 text-lifeworx-blue-100" aria-hidden="true" />
                                    <ExclamationTriangleIcon v-if="notification.variant == 'error'" class="h-6 w-6 text-lifeworx-red-100" aria-hidden="true" />
                                </div>
                                <div class="ml-3 w-0 flex-1">
                                    <p class="text-md font-medium tracking-wide" :class="notification.variant == 'error' ? 'text-lifeworx-red-50' : 'text-lifeworx-blue-50'">{{ notification.message }}</p>
                                    <p v-if="notification.details" class="mt-1 text-sm text-white">{{ notification.details }}</p>
                                </div>
                                <div class="ml-4 flex flex-shrink-0 -mt-0.5">
                                    <button type="button" @click="notificationStore.remove(index)"
                                        class="inline-flex rounded-md p-1 text-white hover:text-lifeworx-blue-800 cursor-pointer hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2" :class="notification.variant == 'error' ? 'focus:ring-lifeworx-red-400' : 'focus:ring-lifeworx-blue-400 '">
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </template>
        </div>
    </div>

</template>

<script setup>

    import { ExclamationTriangleIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/vue/20/solid'

    import { useNotificationStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    const notificationStore = useNotificationStore()

    const { activeNotifications } = storeToRefs(notificationStore)

</script>
