<template>

    <div class="w-full grid grid-cols-6 md:grid-cols-10 h-16 rounded  border-2 border-lifeworx-blue-500 bg-lifeworx-blue-500 overflow-clip">

        <LwxButton class="col-span-2 mt-0.5 md:-mt-0.5 rounded" @click="decrement()">
            <MinusIcon class="h-6 w-6" aria-hidden="true" />
        </LwxButton>

        <input :type="props.currency ? 'hidden' : 'number'" min="0" max="Infinity" step="1" autocomplete="off" v-model="state.number" :value="modelValue" ref="number" class="h-14 mt-0.5 col-span-2 md:col-span-6 text-center rounded border-none appearance-none font-semibold text-xl border-transparent focus:border-transparent focus:ring-0 focus:outline-none" @change="$emit('update:modelValue', $event.target.value)" @keyup="$emit('update:modelValue', $event.target.value)" />

        <input v-if="props.currency" v-model="state.currencyDisplay" ref="currencyDisplay" class="h-14 mt-0.5 col-span-2 md:col-span-6 text-center rounded border-none appearance-none font-semibold text-xl border-transparent focus:border-transparent focus:ring-0 focus:outline-none" @change="updateCurrency()" @keyup="updateCurrency()" />

        <LwxButton class="col-span-2 mt-0.5 md:-mt-0.5 rounded" @click="increment()">
            <PlusIcon class="h-6 w-6" aria-hidden="true" />
        </LwxButton>

    </div>

</template>

<script setup>

    import { ref, reactive, onMounted, watch } from 'vue'

    import { util } from '@/Helpers'

    import { useConfigStore } from '@/Stores'

    import LwxButton from '@/Components/LwxButton.vue'

    import { MinusIcon, PlusIcon } from '@heroicons/vue/24/outline'

    const configStore = useConfigStore()

    const props = defineProps({
        modelValue: {},
        currency: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(['update:modelValue'])

    const number = ref(null)

    const state = reactive({
        number: 1,
        currencyDisplay: ''
    })

    onMounted(() => {
        number.value.value = props.modelValue
        if (props.currency) {
            state.currencyDisplay = '$' + Number(currency(number.value.value)).toFixed(2)
            number.value.dispatchEvent(new Event("change"))
        }
    })

    watch(
        () => state.number,
        (numberstate) => {
            number.value.value = numberstate
            number.value.dispatchEvent(new Event("change"))
        }
    )

    function increment() {
        number.value.value++;
        if (props.currency) {
            state.currencyDisplay = '$' + Number(currency(number.value.value)).toFixed(2)
        }
        number.value.dispatchEvent(new Event("change"))
    }

    function decrement() {
        if (number.value.value >= 1) {
            number.value.value--;
            if (props.currency) {
            state.currencyDisplay = '$' + Number(currency(number.value.value)).toFixed(2)
            }
            number.value.dispatchEvent(new Event("change"));
        }
    }

    function updateCurrency() {
        number.value.value = Number(state.currencyDisplay.replace(/[^0-9.-]+/g, '')).toFixed(2)
        number.value.dispatchEvent(new Event("change"))
    }

    function currency(rate) {
        return (rate).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    }

</script>
