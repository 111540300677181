<template>

    <main>

        <div v-if="auth.verifyDataLoading" class="my-16">
            <h2 class="mt-6 text-center text-2xl font-bold tracking-tight text-lifeworx-blue-300">Loading...</h2>

            <div class="flex text-white justify-center mt-4 p-2">
                <svg class="h-14 w-14 animate-spin text-lifeworx-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="true">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>

        <div v-if="!auth.verifyDataLoading" class="flex min-h-full items-center justify-center py-12">
            <div class="w-full max-w-md md:w-1/2 space-y-8">

                <div @click="router.push('/welcome')" class="text-center cursor-pointer group">
                    <h4 class="inline-flex items-center gap-x-0.5 text-2xl font-bold font-sans text-lifeworx-blue-400 pr-4">
                        <SparklesIcon class="h-7 w-7 text-lifeworx-blue-300 mr-0.5" />
                            New!
                    </h4>
                    <p class="mt-2 mb-4 text-sm font-bold text-lifeworx-blue-600 text-center">
                        Invoicing at LifeWorx has a new look and more features to make getting paid even easier!
                    </p>
                    <button class="text-lifeworx-blue-500 font-bold underline group-hover:no-underline">
                        Read about the changes
                    </button>
                </div>


                <div>
                    <h2 class="mt-10 text-center text-2xl font-bold tracking-tight text-lifeworx-blue-800">Sign in</h2>
                </div>


                <Form @submit="onSubmit" :validation-schema="schema" class="mt-8 space-y-6">

                    <div class="-space-y-px rounded-md shadow-sm">
                        <div>
                            <label for="username" class="sr-only">Email address</label>
                            <Field name="username" type="text" autocomplete="username" required="" class="relative block w-full h-12 rounded-t-md border-0 py-2.5 text-lifeworx-blue-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-600 sm:text-base sm:leading-6" placeholder="Email or Username" />
                        </div>
                        <div>
                            <label for="password" class="sr-only">Password</label>
                            <Field name="password" type="password" autocomplete="current-password" required="" class="relative block w-full h-12 rounded-b-md border-0 py-2.5 text-lifeworx-blue-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-600 sm:text-base sm:leading-6" placeholder="Password" />
                        </div>
                    </div>

                    <div>
                        <LwxButton type="submit" :disabled="state.loading || auth.loading">
                            Sign in
                            <template #icon-before>
                                <LockClosedIcon class="-ml-2 mr-2 h-5 w-5" aria-hidden="true" v-if="!state.loading && !auth.loading" />
                                <svg class="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-else>
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </template>
                        </LwxButton>
                    </div>

                    <div class="grid gap-2 text-lifeworx-red-500 font-bold text-center">
                        <ErrorMessage name="username" />
                        <ErrorMessage name="password" />
                        <span v-if="auth.error">{{ auth.error }}</span>
                    </div>
                    <div v-if="state.message" class="text-lifeworx-green-500 font-bold text-center">
                        <span>{{ state.message }}</span>
                    </div>

                    <div class="flex justify-center items-stretch" v-if="1 == 2">
                        <button type="button" class="mx-auto text-base font-bold leading-loose underline text-center text-lifeworx-blue-600 hover:no-underline">Forgot your password?</button>
                    </div>

                </form>
            </div>
        </div>

    </main>

</template>

<script setup>

    import { reactive, inject, onMounted, computed, nextTick, ref } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { Form, Field, ErrorMessage } from 'vee-validate'
    import * as yup from 'yup';

    import * as Sentry from "@sentry/vue";

    import { useConfigStore, useAuthStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'

    import { LockClosedIcon, SparklesIcon } from '@heroicons/vue/20/solid'

    const router = useRouter()
    const route = useRoute()

    const authStore = useAuthStore()
    const { auth } = storeToRefs(authStore)

    const schema = yup.object({
        username: yup.string().required('Username is required'),
        password: yup.string().required('Password is required')
    })

    const state = reactive({
        loading: false,
        errors: {},
        message: null
    })

    onMounted(() => {
        if (auth.value.authenticated) {
            router.push('/')
        }
        if (auth.value.message) {
            state.message = auth.value.message
        }
    })

    function onSubmit(values, { setErrors }) {
        state.message = null
        const { username, password } = values
        state.loading = true
        authStore.login(username, password)
            .then(() => {
                auth.value.error = null
                // auth.value.loading = false
                state.loading = false
                router.push(route.query.returnUrl || '/')
            }).catch(error => {
                auth.value.loading = false
                state.loading = false
                state.errors.auth = auth.value.error;
                setErrors({ apiError: error })
                Sentry.captureException(error);
        });
    }

</script>
