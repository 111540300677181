<template>

    <div class="w-full md:w-10/12 mx-auto h-22 md:h-20 grid grid-cols-2 text-gray-400">
        <div class="text-xs md:text-base font-semibold leading-snug tracking-tight md:tracking-normal">
            {{ user.profile.first_name }} {{ user.profile.last_name }}<br>
            {{ user.profile.address.street }}<br>
            {{ user.profile.address.city }}, {{ user.profile.address.state }} {{ user.profile.address.zip }}
        </div>
        <div class="text-xs md:text-base font-semibold leading-snug tracking-tight md:tracking-normal text-right">
            {{ invoiceStore.selectedPeriod < invoiceStore.current ? 'Past Invoice ' : 'New Invoice ' }}
            {{ (invoiceStore.invoices && invoiceStore.invoices[invoiceStore.selectedPeriod] && invoiceStore.invoices[invoiceStore.selectedPeriod].id) ? '#' + invoiceStore.invoices[invoiceStore.selectedPeriod].id : '(Draft)' }}<br>
            {{ invoiceStore.friendlyFullDateRange }}<br>
            Pay Period {{ invoiceStore.selected }}
        </div>
    </div>

</template>

<script setup>

    import { onBeforeMount } from 'vue';
    import { useConfigStore, useUserStore, useInvoiceStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()

    const config = configStore
    const { user } = storeToRefs(userStore)


</script>
