<template>

    <div class="flex items-center justify-center cursor-default">

        <span :class="baseClasses + ' ' + status[state.variant].classes">
            {{ status[state.variant].label }}
            <component :is="state.icon" class="mr-2 h-4 w-4" aria-hidden="true"></component>
        </span>

    </div>

</template>

<script setup>

    import { ref, reactive, markRaw, onMounted, onUpdated } from 'vue'

    import { LockOpenIcon, LockClosedIcon, CheckIcon, BellAlertIcon, CurrencyDollarIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid'

    const icon = ref(null)

    const state = reactive({
        icon: null,
        variant: 'closed',
        classes: ''
    })

    const props = defineProps({
        variant: {
            type: String,
            default: 'closed'
        }
    })

    const baseClasses = 'mt-2 inline-flex items-center rounded-full gap-x-1.5 py-1 pl-5 pr-3 text-sm uppercase font-semibold'

    const status = {
        submitted: {
            classes: 'bg-lifeworx-blue-500 text-white',
            label: 'Submitted',
            icon: CheckIcon
        },
        sent: {
            classes: 'bg-lifeworx-blue-500 text-white',
            label: 'Sent',
            icon: CheckIcon
        },
        completed: {
            classes: 'bg-lifeworx-green-500 text-white',
            label: 'completed',
            icon: CurrencyDollarIcon
        },
        approved: {
            classes: 'bg-lifeworx-green-500 text-white',
            label: 'Approved',
            icon: CurrencyDollarIcon
        },
        declined: {
            classes: 'bg-lifeworx-red-500 text-white',
            label: 'Declined',
            icon: ExclamationTriangleIcon
        },
        closed: {
            classes: 'bg-lifeworx-blue-700 text-white',
            label: 'Closed',
            icon: LockClosedIcon
        },
        open: {
            classes: 'bg-lifeworx-green-600 text-white',
            label: 'Open',
            icon: LockOpenIcon
        },
        closing: {
            classes: 'bg-lifeworx-red-500 text-white',
            label: 'Closes Soon',
            icon: BellAlertIcon
        }
    }

    onMounted(() => {
        state.icon = markRaw(status[props.variant].icon)
        if (props.variant) {
            state.variant = props.variant
        }

    })

    onUpdated(() => {
        state.icon = markRaw(status[props.variant].icon)
        if (props.variant) {
            state.variant = props.variant
        }
    })

</script>
