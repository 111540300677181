    import { ref, computed } from 'vue'

    import { defineStore, acceptHMRUpdate } from 'pinia'

    export const useNotificationStore = defineStore('notification', () => {

        const notificationQueue = ref([])

        const showOverlaySubmit = ref(false)
        const overlaySubmitAmount = ref()

        const showOverlayConfirm = ref(false)
        const overlayConfirmData = ref({})
        const overlayConfirmed = ref(false)
        const overlayConfirmedUnwatch = ref()

        const cleanupTimer = setInterval(cleanup, 1000)

        function add(message, variant='message', details) {
            notificationQueue.value.push({
                variant: variant,
                message: message,
                details: details,
                show: true,
                expires: new Date(new Date().getTime() + 5000)
            })
        }

        function cleanup() {
            notificationQueue.value.forEach((notif) => {
                if (notif.expires < new Date()) {
                    notif.show = false
                }
            })
            let i
            for (i = notificationQueue.value.length - 1; i >= 0; i -= 1) {
                if (notificationQueue.value[i].show == false && new Date(notificationQueue.value[i].expires.getTime() + 300) < new Date()) {
                    notificationQueue.value.splice(i, 1);
                }
            }
        }

        function remove(index) {
            notificationQueue.value[index].expires = new Date(new Date().getTime() - 10000)
        }

        const activeNotifications = computed(() => {
            let clone = (x) => x;
            return notificationQueue.value.map(clone).reverse();
        })

        return {
            activeNotifications, add, remove,
            showOverlaySubmit, overlaySubmitAmount,
            showOverlayConfirm, overlayConfirmData, overlayConfirmed, overlayConfirmedUnwatch
        }

    })

    if (import.meta.hot) {
        import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
    }
