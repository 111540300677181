<template>

    <main>

        <div class="mt-3 md:w-1/2 mx-auto grid gap-2">

            <h3 class="text-center text-3xl font-bold my-6">Error</h3>

            <p class="text-center my-4">Sorry, there appears to have been an error.</p>

            <LwxButton @click="router.push({ path: '/invoice/' + selectedPeriod + '' })">
                Go Back
            </LwxButton>

        </div>

    </main>

</template>

<script setup>

    import { reactive } from 'vue'

    import { useConfigStore } from '@/Stores'

    import LwxButton from '@/Components/LwxButton.vue'

    import { useRouter } from 'vue-router'

    const configStore = useConfigStore()
    const config = configStore.config

    const router = useRouter()

    const props = defineProps({
        currentPeriod: {
            type: Number,
            required: true
        },
        period: {
            type: String
        },
        userProfile: {
            type: Object,
            required: true
        }
    })

    const selectedPeriod = useSelectedPeriod()

    function useSelectedPeriod() {
        let selectedPeriod = Number(props.currentPeriod);
        if (props.period) {
            return Number(props.period);
        }
        return selectedPeriod;
    }

    const state = reactive({
        selectedPeriodId: selectedPeriod
    })

</script>
