<template>

    <nav class="h-28">

        <div class="w-full md:w-2/3 grid grid-cols-3 sm:gab-2 md:gap-4 justify-center mx-auto">

            <div class="items-center">

                <LwxButton variant="secondary" @click="router.push({ path: '/invoice/' + invoiceStore.previous })" :disabled="invoiceStore.selected == invoiceStore.oldest || !invoiceStore.previous">
                    Prev
                    <template #icon-before>
                        <ChevronLeftIcon class="-ml-2 mr-2 h-5 w-5" aria-hidden="true" />
                    </template>
                </LwxButton>

            </div>

            <div class="items-center">

                <div class="text-lifeworx-blue-500">
                    <div class="grid grid-cols-2 items-center justify-center w-full h-4 pt-3.5 md:pt-3">
                        <span class="w-full h-full text-xs font-bold leading-none text-center pl-4 tracking-wide">Sun</span>
                        <span class="w-full h-full text-xs font-bold leading-none text-center pr-4 tracking-wide">Sat</span>
                    </div>
                    <div class="w-full text-sm md:text-lg font-bold tracking-wide text-center pt-3 md:pt-2">
                        {{ invoiceStore.friendlyDateRange }}
                    </div>

                </div>

            </div>

            <div class="items-center">
                <LwxButton variant="secondary" @click="router.push({ path: '/invoice/' + invoiceStore.next })" :disabled="invoiceStore.selected == currentPeriod.id">
                    Next
                    <template #icon-after>
                        <ChevronRightIcon class="ml-2 -mr-2 h-5 w-5" aria-hidden="true" />
                    </template>
                </LwxButton>

            </div>

        </div>

        <div class="items-center pt-2">
            <InvoiceStatus :variant="selectedStatus" />
        </div>

    </nav>

</template>

<script setup>

    import { onBeforeMount, reactive, watch, onUpdated } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import { useConfigStore, useUserStore, useInvoiceStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'

    import InvoiceStatus from '@/Components/InvoiceStatus.vue'
    import LwxButton from '@/Components/LwxButton.vue'

    const route = useRoute()
    const router = useRouter()
    const emit = defineEmits(['loadInvoice'])

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()

    const { config } = storeToRefs(configStore)
    const { user } = storeToRefs(userStore)
    const { invoices, selectedPeriod, selectedStatus } = storeToRefs(invoiceStore)

    const currentPeriod = config.value.currentPeriod

    const state = reactive({
        friendlyDateRange: null
    })

    watch(
        () => route.fullPath,
        async () => {
            if (route.params.period) {
                selectedPeriod.value = route.params.period
                emit('loadInvoice', route.params.period)
            } else {
                selectedPeriod.value = currentPeriod.period_id
                emit('loadInvoice', currentPeriod.period_id)
            }
        }
    )

</script>
