<template>

    <main>

        <div class="sm:max-w-full md:w-10/12 mx-auto grid gap-2 my-6">

            <p class="text-base font-semibold italic">Be sure you have entered ALL shifts for ALL clients for this week before you submit. Once you submit, no additional shift information can be added for this period.</p>

            <LwxHr class="my-8 md:my-6" />

            <p class="text-base font-semibold">I, <span class="underline underline-offset-2">{{ userProfile.first_name + ' ' + userProfile.last_name }}</span>, certify that the information I have provided is true and correct. I know it is a crime to complete submit this form with information I know is false or to omit any facts I know are important.</p>

        </div>

        <div class="sm:max-w-full md:w-2/3 mx-auto bg-gray-200 hover:bg-lifeworx-blue-400 border-8 border-gray-200 hover:border-lifeworx-blue-400 cursor-pointer rounded">

            <div class="w-full h-full overflow-hidden bg-gray-100 rounded">

                <VueSignaturePad width="100%" height="150px" ref="certifySignature" />

                 <div class="py-2 px-3 text-center">
                    <span v-show="!state.certified">
                        Please draw your signature in the area above to continue.
                    </span>
                    <span v-show="state.certified">
                        Signature recorded at {{ state.certified }}
                    </span>
                </div>

            </div>

        </div>

        <div class="mt-3 md:w-1/2 mx-auto grid gap-2">

            <div class="col-span-2 mt-2">
                <LwxButton @click="certifyInvoice()">
                    Submit Invoice
                </LwxButton>
            </div>

            <div class="col-span-2 mt-2">
                <LwxButton @click="router.push({ path: '/invoice/' + selectedPeriod + '' })" variant="secondaryRed">
                    Edit Invoice
                </LwxButton>
            </div>

        </div>

    </main>

</template>

<script setup>

    import { reactive, ref, watch } from 'vue'
    import { useRouter } from 'vue-router'

    import '@/Helpers'

    import { useConfigStore, useUserStore, useInvoiceStore, useNotificationStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'

    import { VueSignaturePad } from 'vue-signature-pad'

    const router = useRouter()

    const emit = defineEmits(['saveData'])

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()
    const notificationStore = useNotificationStore()

    const config = configStore.config;
    const { user } = storeToRefs(userStore)
    const { invoices, selectedPeriod } = storeToRefs(invoiceStore)

    const certifySignature = ref(null)

    const props = defineProps({
        currentPeriod: {
            type: Number,
            required: true
        },
        period: {
            type: String
        },
        userProfile: {
            type: Object,
            required: true
        }
    })

    function certifyInvoice() {
        const { isEmpty, data } = certifySignature.value.saveSignature();
        if (isEmpty) {
            return false;
        } else {
            state.certified = new Date();
            state.signature = data

            emit('saveData', {
                type: 'certification',
                period: selectedPeriod.value,
                data: {
                    submitted: state.certified,
                    signature_image: state.signature
                }
            })

            router.push({ path: '/invoice/' + selectedPeriod.value })

        }
    }

    const state = reactive({
        selectedPeriodId: selectedPeriod,
        certified: null,
        signature: null
    })

    watch(() => invoices.value, (selected) => {

        if (invoices.value && props.period) {
            if (!invoices.value[props.period]) {
                notificationStore.add('Error 404', 'error', 'Invoice not found')
                router.push({ path: '/' })
            } else if (!['open', 'declined'].includes(invoices.value[props.period].status)) {
                notificationStore.add('Error 403', 'error', 'Invoice unable to be certified')
                router.push({ path: '/invoice/' + props.period })
            }
        }

    }, { immediate: true })

</script>
