<template>

    <nav class="bg-lifeworx-blue-500 shadow-md" :class="{ 'bg-purple-500': appEnv == 'development' }, { 'bg-orange-500': appEnv == 'staging' }">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-20 items-center justify-between">
                <div class="flex items-center cursor-pointer" @click="router.push({ path: '/' })">
                    <div class="flex-shrink-0">
                        <img class="block h-8 w-auto" src="@/../assets/lifeworx.svg" alt="LifeWorx" />
                        <h3 class="font-serif text-white italic mt-1 tracking-wider">
                            {{ subtitle }} <span v-if="appEnv" class="capitalize">&bull; {{ appEnv }}</span>
                        </h3>
                    </div>
                </div>
                <div v-if="auth.loading">
                    <div class="hidden sm:ml-6 sm:block">
                        <div class="flex items-center">
                            <div class="relative ml-3">
                                <div>
                                    <div class="flex text-white justify-center rounded-md p-2">
                                        <svg class="h-10 w-10 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="true">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="auth.authenticated" class="-mr-2 flex sm:hidden">
                        <div class="flex text-white justify-center rounded-md p-2">
                            <svg class="h-8 w-8 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="true">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <Menu v-if="auth.authenticated" as="div" class="relative ml-3">
                        <div>
                            <MenuButton class="flex justify-center items-center">
                                <div class="relative flex rounded text-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 border-2 border-white">
                                    <Bars3Icon class="block h-8 w-8" aria-hidden="true" />
                                </div>
                            </MenuButton>
                        </div>
                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem
                                    v-slot="{ active }"
                                >
                                    <button
                                        @click="router.push('/settings')"
                                        :class="[active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700']"
                                    >
                                        Settings
                                    </button>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <button
                                        @click="authStore.logout()"
                                        :class="[active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700']"
                                    >
                                        Log out
                                    </button>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
        </div>
    </nav>

</template>

<script setup>

    import { useAuthStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { Bars3Icon } from '@heroicons/vue/24/outline'
    import { useRouter } from 'vue-router'

    import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

    const router = useRouter()

    const authStore = useAuthStore()
    const { auth } = storeToRefs(authStore)

    defineProps({
        subtitle: {
            type: String,
            required: true
        }
    })

    const appEnv = import.meta.env.VITE_APP_ENVIRONMENT !== 'production' && import.meta.env.VITE_NAVBAR_ENV ? import.meta.env.VITE_APP_ENVIRONMENT : null

</script>
