<template>

    <LwxBreadcrumb :pages="[{ name: state.id ? 'Edit Mileage' : 'Add Mileage', href: '/invoice/' + selectedPeriod + '/add-trip', current: true }]"></LwxBreadcrumb>

    <div class="w-full mx-auto pt-2 font-2xl font-bold text-lifeworx-green-500">

        <div v-if="state.client" class="py-2 hover:bg-green-100 rounded cursor-pointer md:px-4 flex flex-row" @click="resetClient()">
            <h4 class="grow">
                1. Client:&nbsp;&nbsp; {{ state.client.first_name + ' ' + state.client.last_name }}
            </h4>
            <div class="shrink justify-items-end">
                <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
            </div>
        </div>

        <div v-if="state.trip" class="py-2 hover:bg-green-100 rounded cursor-pointer md:px-4 flex flex-row" @click="resetTrip()">
            <h4 class="grow">
                2. Mileage:&nbsp;&nbsp; {{ state.miles }} miles ( {{ mileageRateCalc }} )
            </h4>
            <div class="shrink justify-items-end">
                <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
            </div>
        </div>

    </div>

    <div class="w-full mx-auto pt-2">

        <div v-if="!state.client">
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">1. Select Client</h1>

            <div class="grid gap-4 grid-cols-1 w-full md:w-1/2 mx-auto pt-2 ">

                <template v-for="client in userStore.user.clients">

                    <LwxButton v-if="client.active" @click="selectClient(client)" variant="secondary">
                        {{ client.first_name + ' ' + client.last_name }}
                    </LwxButton>

                </template>

            </div>
        </div>

        <div v-if="state.client && !state.trip">
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">2. Enter Mileage</h1>

            <div class="grid gap-4 grid-cols-1 w-full md:w-1/2 mx-auto pt-2 ">

                <LwxNumber v-model="state.miles" />

                <div class="text-center text-lifeworx-blue-800 font-bold">
                    {{ mileageRateCalc }} <span class="text-gray-400">(@ {{ util.currency(config.mileageRate * 0.01) }}/mile)</span>
                </div>

                <LwxButton @click="selectTrip()" :disabled="state.miles < 1">
                    Continue
                </LwxButton>

            </div>
        </div>

        <div v-if="state.client && state.trip">
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">3. Enter a Memo</h1>

            <div class="grid gap-4 grid-cols-1 w-full md:w-2/3 mx-auto pt-2 ">

                <textarea rows="6" name="memoField" id="memoField" ref="memoField" v-model="state.memo" class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-600 sm:py-4 sm:text-md sm:leading-6" placeholder="Enter any comments" />

            </div>

            <div class="grid gap-4 grid-cols-1 w-full md:w-1/2 mx-auto pt-4 ">

                <LwxButton @click="saveTrip()" :disabled="!state.memo">
                    Save Mileage &amp; Memo
                </LwxButton>

            </div>
        </div>

    </div>

    <div class="w-full mx-auto pt-2 md:px-4">

        <div v-if="!state.client && !state.miles" class="py-2">
            <h4 class="font-2xl font-bold text-gray-400">
                2. Enter Mileage
            </h4>
        </div>

        <div v-if="!state.client && !state.trip" class="py-2">
            <h4 class="font-2xl font-bold text-gray-400">
                3. Enter a Memo
            </h4>
        </div>

    </div>


    <div class="w-full md:w-1/2 mx-auto">

        <LwxHr class="my-8 md:my-6" />

        <div class="grid gap-4 pt-2">

            <LwxButton @click="router.push({ path: '/invoice/' + selectedPeriod })" variant="primaryRed">
                {{ state.id ? 'Cancel Edits' : 'Cancel Mileage' }}
            </LwxButton>

            <LwxButton v-if="state.id" @click="deleteTrip()" variant="secondaryRed">
                Delete Mileage
            </LwxButton>

        </div>


    </div>

</template>

<script setup>

    import { reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'

    import { util } from '@/Helpers'

    import { useConfigStore, useUserStore, useInvoiceStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'
    import LwxNumber from '@/Components/LwxNumber.vue'
    import LwxBreadcrumb from '@/Components/LwxBreadcrumb.vue'

    import { PencilSquareIcon } from '@heroicons/vue/20/solid'

    const router = useRouter()

    const emit = defineEmits(['saveData', 'deleteData', 'error'])

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()

    const config = configStore.config
    const { user } = storeToRefs(userStore)
    const { invoices, selectedPeriod } = storeToRefs(invoiceStore)

    const props = defineProps({
        period: {
            type: String
        },
        userProfile: {
            type: Object,
        },
        currentPeriod: {
            type: Number,
        },
        id: {
            type: String
        }
    })

    const state = reactive({
        client: null,
        trip: 0,
        miles: 0,
        memo: null,
        id: null
    })

    onMounted(() => {

        // Check for expense ID to edit (TODO: FIX)
        if (props.id) {

            if (invoices.value && invoices.value[ invoiceStore.selected ] && invoices.value[ invoiceStore.selected ].trips) {

                let editData = invoices.value[ invoiceStore.selected ].trips.filter(function (el) {
                    return el.id == props.id;
                })

                if (editData[0]) {
                    state.id = editData[0].id
                    state.client = user.value.clients[editData[0].client_id]
                    state.trip = editData[0].distance
                    state.miles = editData[0].distance
                    state.memo = util.decodeString(editData[0].memo)
                } else {

                    emit('error', {
                        type: 'trip',
                        code: 404
                    })

                    router.push({ path: '/invoice/' + selectedPeriod.value })

                }

            } else {

                emit('error', {
                    type: 'trip',
                    code: 404
                })

                router.push({ path: '/invoice/' + selectedPeriod.value })

            }

        }

        // If there is only one client available, select it automatically
        if (user.value.clients.length == 1) {
            state.client = user.value.clients[0]
        }

    })


    function selectClient(client) {
        state.client = client;
    }

    function selectTrip() {
        state.trip = state.miles;
    }

    function resetClient() {
        state.client = null;
        state.trip = null;
    }

    function resetTrip() {
        state.trip = null;
    }

    function saveTrip() {

        emit('saveData', {
            type: 'trip',
            period: selectedPeriod.value,
            id: state.id,
            data: {
                client: state.client,
                distance: state.trip,
                memo: state.memo
            }
        })

        router.push({ path: '/invoice/' + selectedPeriod.value })

    }

    function deleteTrip() {

        emit('deleteData', {
            type: 'trip',
            period: selectedPeriod.value,
            id: state.id
        })

        router.push({ path: '/invoice/' + selectedPeriod.value })

    }

    const mileageRateCalc = computed(() => {
        return util.currency(state.miles * (config.mileageRate * 0.01));
    })

</script>
