<template>

    <TransitionRoot as="template" :show="showOverlayConfirm">
        <Dialog as="div" class="relative z-10" @close="close()">

            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-lifeworx-red-600 bg-opacity-90 backdrop-blur-sm transition-opacity" :class="{ 'bg-orange-600': overlayConfirmData.can_import }"/>
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 z-10">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <div>

                                <ExclamationTriangleIcon class="h-60 w-60 mx-auto text-white" aria-hidden="true" />

                                <div class="mt-3 text-center sm:mt-5">

                                    <DialogTitle as="h3" class="text-3xl font-extrabold leading-10 text-white">
                                        {{ overlayConfirmData.can_import ? 'Please Confirm:' : 'Unable to Proceed'}}
                                    </DialogTitle>
                                    <div class="mt-6 font-semibold tracking-wide">
                                        <p class="text-md text-white">
                                            <span v-for="message in overlayConfirmData.message">
                                                {{ message + ' ' }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="sm:max-w-full  mx-auto grid gap-4 my-4">
                                <LwxButton v-if="overlayConfirmData.can_import" @click="confirm()" variant="primaryWhite">
                                    Confirm
                                </LwxButton>
                                <LwxButton @click="close()" variant="secondaryWhite">
                                    {{ overlayConfirmData.can_import ? 'Cancel' : 'Okay'}}
                                </LwxButton>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>

        </Dialog>
    </TransitionRoot>

</template>

<script setup>

    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

    import { useNotificationStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'

    import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

    const notificationStore = useNotificationStore()

    const { showOverlayConfirm, overlayConfirmData, overlayConfirmed } = storeToRefs(notificationStore)

    function confirm() {
        overlayConfirmed.value = true
        showOverlayConfirm.value = false
    }

    function close() {
        showOverlayConfirm.value = false
        notificationStore.overlayConfirmedUnwatch()
    }

</script>
