// Global utilities
import moment from 'moment';

export const util = {

    // Formats an integer or float for display as currency
    currency: (rate) => {
        return Number(rate).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    },
    // Converts line breaks to HTML
    // See: https://www.php.net/manual/en/function.nl2br.php
    nl2br: (str) => {
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    },
    // Converts a string to Title Case
    titleCase: (str) => {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    },
    // Converts a number/integer of minutes to rounded-down hours and the remainding number of minutes
    toHoursAndMinutes: (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return { hours, minutes };
    },
    // Returns a Date object from the frequently-used YYYY-MM-DD format
    makeDate: (dateString) => {
        return new Date(
            dateString.substr(0, 4),
            Number(dateString.substr(5, 2)) - 1,
            Number(dateString.substr(8, 2))
        );
    },
    // Converts UTC date from server to local time
    makeLocalTime: (date) => {
        let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    },
    getDateFromString(dateString) {
        return moment(dateString, 'YYYY-MM-DD HH:mm:ss').toDate();
    },
    // Excessive date formatting function
    // See: https://gist.github.com/kubiqsk/c60207a3075104df7cc1822a95053ecd
    formatDate: (formatStr, date, locale = navigator.language) => {

        const replaceChars = {
            // day
            d: function () { return ('0' + this.getDate()).slice(-2) },
            D: function (locale) { return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(this) },
            j: function () { return this.getDate() },
            l: function (locale) { return new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(this) },
            N: function () {
                let day = this.getDay();
                return day === 0 ? 7 : day;
            },
            S: function () {
                let date = this.getDate();
                return date % 10 === 1 && date !== 11 ? 'st' : (date % 10 === 2 && date !== 12 ? 'nd' : (date % 10 === 3 && date !== 13 ? 'rd' : 'th'));
            },
            J: function () {
                let date = this.getDate();
                return this.getDate() + (date % 10 === 1 && date !== 11 ? 'st' : (date % 10 === 2 && date !== 12 ? 'nd' : (date % 10 === 3 && date !== 13 ? 'rd' : 'th')));
            },
            w: function () { return this.getDay() },
            z: function () { return Math.floor((this - new Date(this.getFullYear(), 0, 1)) / 86400000) },
            // week
            W: function () {
                let target = new Date(this.valueOf());
                let dayNr = (this.getDay() + 6) % 7;
                target.setDate(target.getDate() - dayNr + 3);
                let firstThursday = target.valueOf();
                target.setMonth(0, 1);
                if (target.getDay() !== 4) {
                    target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
                }
                return Math.ceil((firstThursday - target) / 604800000) + 1;
            },
            // month
            F: function (locale) { return new Intl.DateTimeFormat(locale, { month: 'long' }).format(this) },
            m: function () { return ('0' + (this.getMonth() + 1)).slice(-2) },
            M: function (locale) { return new Intl.DateTimeFormat(locale, { month: 'short' }).format(this) },
            n: function () { return this.getMonth() + 1 },
            t: function () {
                let year = this.getFullYear();
                let nextMonth = this.getMonth() + 1;
                if (nextMonth === 12) {
                    year = year++;
                    nextMonth = 0;
                }
                return new Date(year, nextMonth, 0).getDate();
            },
            // year
            L: function () {
                let year = this.getFullYear();
                return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0) ? 1 : 0;
            },
            o: function () {
                let date = new Date(this.valueOf());
                date.setDate(date.getDate() - ((this.getDay() + 6) % 7) + 3);
                return date.getFullYear();
            },
            Y: function () { return this.getFullYear() },
            y: function () { return ('' + this.getFullYear()).slice(-2) },
            // time
            a: function () { return this.getHours() < 12 ? 'am' : 'pm' },
            A: function () { return this.getHours() < 12 ? 'AM' : 'PM' },
            K: function () { return this.getHours() < 12 ? 'AM' : 'PM' },
            B: function () {
                return ('00' + Math.floor((((this.getUTCHours() + 1) % 24) + this.getUTCMinutes() / 60 + this.getUTCSeconds() / 3600) * 1000 / 24)).slice(-3);
            },
            g: function () { return this.getHours() % 12 || 12 },
            G: function () { return this.getHours() },
            h: function () { return ('0' + (this.getHours() % 12 || 12)).slice(-2) },
            H: function () { return ('0' + this.getHours()).slice(-2) },
            i: function () { return ('0' + this.getMinutes()).slice(-2) },
            s: function () { return ('0' + this.getSeconds()).slice(-2) },
            v: function () { return ('00' + this.getMilliseconds()).slice(-3) },
            // Timezone
            e: function () { return Intl.DateTimeFormat().resolvedOptions().timeZone },
            I: function () {
                let DST = null;
                for (let i = 0; i < 12; ++i) {
                    let d = new Date(this.getFullYear(), i, 1);
                    let offset = d.getTimezoneOffset();
                    if (DST === null) {
                        DST = offset;
                    } else if (offset < DST) {
                        DST = offset;
                        break;
                    } else if (offset > DST) {
                        break;
                    }
                }
                return (this.getTimezoneOffset() === DST) | 0;
            },
            O: function () {
                let timezoneOffset = this.getTimezoneOffset();
                return (-timezoneOffset < 0 ? '-' : '+') + ('0' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2) + ('0' + Math.abs(timezoneOffset % 60)).slice(-2);
            },
            P: function () {
                let timezoneOffset = this.getTimezoneOffset();
                return (-timezoneOffset < 0 ? '-' : '+') + ('0' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2) + ':' + ('0' + Math.abs(timezoneOffset % 60)).slice(-2);
            },
            T: function (locale) {
                let timeString = this.toLocaleTimeString(locale, { timeZoneName: 'short' }).split(' ');
                let abbr = timeString[timeString.length - 1];
                return abbr == 'GMT+1' ? 'CET' : (abbr == 'GMT+2' ? 'CEST' : abbr);
            },
            Z: function () { return -this.getTimezoneOffset() * 60 },
            // Full Date/Time
            c: function () { return this.format('Y-m-d\\TH:i:sP') },
            r: function () { return this.format('D, d M Y H:i:s O') },
            U: function () { return Math.floor(this.getTime() / 1000) }
        }

        return formatStr.replace(/(\\?)(.)/g, function (_, esc, chr) {
            return esc === '' && replaceChars[chr] ? replaceChars[chr].call(date, locale) : chr
        });
    },
    // Human-readable shift length string
    friendlyShiftLength: (shiftStart, shiftEnd) => {
        const start = util.getDateFromString(shiftStart).valueOf();
        const end = util.getDateFromString(shiftEnd).valueOf();
        const totals = util.toHoursAndMinutes((end - start) / 60000);
        let string = totals.hours + ' hours';
        if (totals.minutes > 0) {
            string += ', ' + totals.minutes + 'min';
        }
        return string;
    },
    // handles whether DST change occurs, -1 => standard -> daylight, 1 => daylight -> standard, 0 => no change
    getDstChangePosition: (startDateTime, endDateTime) => {
        if (!startDateTime.isDST() && endDateTime.isDST()) {
            return -1;
        } else if (startDateTime.isDST() && !endDateTime.isDST()) {
            return 1;
        }
        return 0;
    },
    // Convert minutes to hours (I know... I know...)
    toHours: (totalMinutes) => {
        return totalMinutes / 60;
    },
    // Return shift pay in number format, given start and end times, as well as exact rate
    shiftTotal: (shiftStart, shiftEnd, rate) => {
        const start = util.getDateFromString(shiftStart).valueOf();
        const end = util.getDateFromString(shiftEnd).valueOf();
        return Number(util.toHours((end - start) / 60000) * rate).toFixed(2);
    },
    // Return trip value based on mileage rate in global config, in cents
    tripTotal: (trip, config) => {
        return Number(trip.distance * (config.mileageRate * 0.01));
    },
    // Adds a day of padding to a date
    addDayPadding: (date) => {
        var result = new Date(date);
        result.setDate(result.getDate() + 1);
        return result;
    },
    // Returns a friendly date string
    makeFriendlyDate: (dateString, config) => {
        return util.formatDate(config.friendlyDateFormat, moment(dateString, 'YYYY-MM-DD HH:mm:ss').toDate());
    },
    // Returns a compact friendly date string
    makeFriendlyShortDate: (dateString, config) => {
        return util.formatDate(config.friendlyShortDateFormat, moment(dateString, 'YYYY-MM-DD HH:mm:ss').toDate());
    },
    // Returns the total value of the shifts based on their included rates
    sumShifts: (shifts, rates) => {
        let total = 0;
        shifts.forEach(shift => {
            total += Number(util.shiftTotal(shift.start, shift.end, rates[shift.rate_id].amount));
        }, rates);
        return total;
    },
    // Returns the total value of the trips based on the mileage rate in global config
    sumMileage: (trips, config) => {
        let total = 0;
        trips.forEach(trip => {
            total += Number(util.tripTotal(trip, config));
        });
        return total;
    },
    // Returns the total value of the expenses because math is hard
    sumExpenses: (expenses) => {
        let total = 0;
        expenses.forEach(expense => {
            total += Number(expense.amount);
        });
        return total;
    },
    // Returns the remaining value of the shifts after the standard deduction rate in global config
    shiftsDeductions: (shifts, config, rates) => {
        return util.sumShifts(shifts, rates) * (config.deduction / 100);
    },
    // Returns the invoice total based on all data provided and the global config
    invoiceTotal: (invoice, config, rates) => {
        let total = 0;
        if (invoice.shifts && invoice.shifts.length > 0) {
            total += Number((util.sumShifts(invoice.shifts, rates) - util.shiftsDeductions(invoice.shifts, config, rates)));
        }
        if (invoice.trips && invoice.trips.length > 0) {
            total += Number(util.sumMileage(invoice.trips, config));
        }
        if (invoice.expenses && invoice.expenses.length > 0) {
            total += Number(util.sumExpenses(invoice.expenses));
        }
        return total;
    },
    // Returns an object with keys determined by the second parameter
    // Used for remapping all invoices to an object structured by period_id instead of invoice_id
    convertArrayToObject: (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    },
    // Escape any errant HTML in a user-provided string
    escapeString: (str) => {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };
        return str.replace(/[&<>"']/g, function (m) { return map[m]; });
    },
    decodeString: (str) => {
        var map = {
            '&amp;': '&',
            '&lt;': '<',
            '&gt;': '>',
            '&quot;': '"',
            '&#039;': "'"
        };
        return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function (m) { return map[m]; });
    },
    // Comparison function for sorting shifts
    shiftCompare: (a, b) => {
        if (a.start < b.start) {
            return -1;
        }
        if (a.start > b.start) {
            return 1;
        }
        return 0;
    },
    // It's 2003 all over again... scrolls to the provided element, if found
    scrollToElement: (target) => {
        const el = document.getElementById(target);
        if (el) {
            el.scrollIntoView({ block: "center", inline: "nearest", behavior: "instant" });
        }
    }

}
