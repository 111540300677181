import { createRouter, createWebHistory } from 'vue-router'

import { util } from '@/Helpers'

import { useAuthStore } from '@/Stores'

import InvoiceView from '@/Pages/InvoiceView.vue'
import AddShiftView from '@/Pages/AddShiftView.vue'
import AddNotesView from '@/Pages/AddNotesView.vue'
import AddTripView from '@/Pages/AddTripView.vue'
import AddExpenseView from '@/Pages/AddExpenseView.vue'
import CertifyView from '@/Pages/CertifyView.vue'
import LoginView from '@/Pages/LoginView.vue'
import WelcomeView from '@/Pages/WelcomeView.vue'
import ErrorView from '@/Pages/ErrorView.vue'
import SettingView from '@/Pages/SettingView.vue'

import { useNotificationStore } from '@/Stores'

const baseTitle = ' - LifeWorx Caregiver Portal'

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "welcome",
            component: InvoiceView,
        },
        {
            path: "/welcome",
            name: "release-notes",
            component: WelcomeView,
        },
        {
            path: "/login",
            name: "sign-in",
            component: LoginView,
        },
        {
            path: "/settings",
            name: "settings",
            component: SettingView,
        },
        { path: "/invoice/null", redirect: "/" },
        {
            path: "/invoice/:period",
            name: "view-invoice",
            component: InvoiceView,
            props: true,
        },
        {
            path: "/invoice/:period/add-shift/:id?",
            name: "add-shift",
            component: AddShiftView,
            props: true,
        },
        {
            path: "/invoice/:period/add-notes",
            name: "add-notes",
            component: AddNotesView,
            props: true,
        },
        {
            path: "/invoice/:period/add-trip/:id?",
            name: "add-trip",
            component: AddTripView,
            props: true,
        },
        {
            path: "/invoice/:period/add-expense/:id?",
            name: "add-expense",
            component: AddExpenseView,
            props: true,
        },
        {
            path: "/invoice/:period/certify",
            name: "certify-invoice",
            component: CertifyView,
            props: true,
        },
        {
            path: "/error/:period?",
            name: "error",
            component: ErrorView,
            props: true,
        },

    ],
})

router.beforeEach((to, from, next) => {
    if (to && to.name && next) {
        document.title = util.titleCase(to.name.replace('-', ' ')) + baseTitle;
        next();
    }
})

router.beforeEach(async (to) => {
    const publicPages = ['/login', '/welcome'];
    const authRequired = !publicPages.includes(to.path);
    const { auth } = useAuthStore();
    if (authRequired && (!auth.authenticated)) {
        return {
            path: '/login',
            query: { returnUrl: to.href }
        }
    }
    if (auth.authenticated && to.path == '/login') {
        return {
            path: '/'
        }
    }
    if (to.params.period && isNaN(to.params.period)) {
        const notificationStore = useNotificationStore()
        notificationStore.add('Error 404', 'error', 'Invoice not found')
        return {
            path: '/'
        }
    }
})

export default router;
