<template>

    <section class="my-12 mx-auto max-w-md">

        <h2 class="text-xl font-bold leading-loose text-center text-lifeworx-blue-800">Questions?</h2>

        <h3 class="text-sm font-bold leading-normal text-center text-gray-500 px-5">Contact your Candidate Relations Manager with updates to your work availability or any other concerns:</h3>

        <div class="divide-y divide-gray-200 rounded-lg bg-gray-100 shadow-md my-4">
            <div class="flex w-full items-center justify-between space-x-6 p-6">
                <div class="flex-1 truncate">
                    <h3 class="truncate text-xl font-bold text-gray-900">{{ recruiter.first_name }} {{ recruiter.last_name }}</h3>
                    <p class="mt-1 truncate text-sm text-gray-500">{{ recruiter.office }} Office</p>
                </div>
                <img class="h-20 w-20 flex-shrink-0 rounded-full bg-gray-300" :src="baseUrl + '/assets/recruiter-photos/' + recruiter.profile_id + '.jpg'" :alt="recruiter.first_name + ' ' + recruiter.last_name" />
            </div>
            <div>
                <div class="-mt-px flex divide-x divide-gray-200">
                    <div class="flex w-0 flex-1">
                        <a :href="'tel:+1' + recruiter.phoneCall" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-lifeworx-blue-500 hover:bg-gray-200 hover:text-lifeworx-blue-600">
                            <PhoneArrowUpRightIcon class="-mr-2 h-5 w-5" aria-hidden="true" />
                            Call
                        </a>
                    </div>
                    <div class="-ml-px flex w-0 flex-1">
                        <a :href="'sms:+1' + recruiter.phoneSms" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-lifeworx-blue-500 hover:bg-gray-200 hover:text-lifeworx-blue-600">
                            <ChatBubbleLeftRightIcon class="-mr-2 h-5 w-5" aria-hidden="true" />
                            SMS Text
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>

<script setup>

    import { PhoneArrowUpRightIcon } from '@heroicons/vue/24/solid'
    import { ChatBubbleLeftRightIcon } from '@heroicons/vue/24/solid'

    const props = defineProps({
        recruiter: {
            type: Object,
            required: true
        }
    })

    const baseUrl = import.meta.env.VITE_STORAGE_PATH;

</script>
