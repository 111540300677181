<template>

    <div class="w-full" v-if="invoices && invoices[selectedPeriod] && invoices[selectedPeriod].shifts && (invoices[selectedPeriod].shifts.length == 0) && (selectedPeriod == invoiceStore.current)">
        <h3 class="text-2xl font-semibold leading-loose text-center text-lifeworx-blue-900" >
            {{ invoices && Object.keys(invoices).length < 0 ? 'Welcome' : 'Welcome back' }}, {{ user.profile.first_name }}! {{  }}
        </h3>

        <LwxHr class="my-6 md:my-4" />

        <h4 v-if="selectedPeriod == invoiceStore.current" class="w-80 mx-auto text-base font-bold leading-loose text-center text-lifeworx-blue-900">
            Has your work schedule changed?</h4>
    </div>

    <div class="sm:max-w-full md:w-1/2 mx-auto pt-2" v-if="invoices && invoices[selectedPeriod] && (!invoices[selectedPeriod].shifts || invoices[selectedPeriod].shifts.length == 0) && (selectedPeriod == currentPeriod) && ['open', 'declined'].includes(selectedStatus)">

        <LwxButton @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-shift' })">
            Add New Shift
        </LwxButton>

        <p class="my-2 text-base font-bold leading-loose text-center text-lifeworx-blue-900">or</p>

        <LwxButton @click="invoiceStore.copyLastInvoice()">
            Copy Last Invoice
        </LwxButton>

        <p class="my-2 text-base font-bold leading-loose text-center text-lifeworx-blue-900">You'll be able to make changes after.</p>

        <div class="justify-center items-stretch grid  grid-cols-1">
            <button @click="userStore.toggleInvoiceReminders()" class="mx-auto text-base font-bold leading-loose underline text-center hover:no-underline" :class="user.profile.inv_reminder == 1 ? 'text-lifeworx-red-500' : 'text-lifeworx-green-600'">
                {{ user.profile.inv_reminder == 1 ? 'I am not working this week, disable reminders' : 'I am working this week, enable reminders' }}
            </button>
            <span class="mx-auto text-sm font-bold leading-loose text-center text-gray-400">
                Reminders are <span :class="user.profile.inv_reminder == 1 ? 'text-lifeworx-green-600' : 'text-lifeworx-red-500'">{{ user.profile.inv_reminder == 1 ? 'enabled' : 'disabled' }}</span> for this week.
            </span>
        </div>

    </div>

    <div v-if="invoices && invoices[selectedPeriod] && ((invoices[selectedPeriod].shifts && invoices[selectedPeriod].shifts.length > 0) || (invoices[selectedPeriod].expenses && invoices[selectedPeriod].expenses.length > 0) || (invoices[selectedPeriod].trips && invoices[selectedPeriod].trips.length > 0))">

        <LwxHr v-if="invoices[selectedPeriod].notes" class="my-6 md:my-8" />

        <h3 class="text-center font-bold text-lifeworx-blue-800 text-xl">
            Invoice Total:
            <span class="text-lifeworx-green-500">
                {{ util.currency(util.invoiceTotal(invoices[selectedPeriod], config, user.rates)) }}
            </span>
        </h3>

    </div>

    <div v-if="invoices && invoices[selectedPeriod] && !['open', 'closed', 'declined'].includes(invoices[selectedPeriod].status) && invoices[selectedPeriod].submitted" class="mt-4">

        <div class="sm:max-w-[75%] md:max-w-[50%] mx-auto bg-gray-100 border-8 border-gray-100 rounded relative cursor-default">

            <div class="w-full h-full rounded">

                <img v-if="invoices[selectedPeriod].signature" :src="invoices[selectedPeriod].signature" :alt="user.profile.first_name + ' ' + user.profile.last_name + ' Signature'" class="w-full h-auto">

                <div v-else class="w-full h-auto py-8 text-center font-cursive text-5xl tracking-wide text-black select-none">
                    {{ user.profile.first_name + ' ' + user.profile.last_name }}
                </div>

                <div class="py-2 px-3 text-center text-xs text-gray-500">
                    Signature recorded {{ util.makeFriendlyDate(invoices[selectedPeriod].submitted, config) }}
                </div>

            </div>
            <div class="absolute -top-4 -right-4 p-3 bg-gray-300 rounded-full">
                <LockClosedIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>

        </div>

    </div>

    <div class="sm:max-w-full md:w-1/2 mx-auto" v-if="invoices && invoices[selectedPeriod] && (selectedPeriod == invoiceStore.current) && ['open', 'declined'].includes(selectedStatus)">

        <div class="mt-6">

            <LwxButton variant="secondary" @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-notes' })">
                {{ !invoices[selectedPeriod].notes ? 'Add Notes' : 'Edit Notes' }}
            </LwxButton>

        </div>

        <div class="mt-3 grid grid-cols-2 gap-3">

            <LwxButton variant="secondary" @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-trip' })">
                Add Mileage
            </LwxButton>

            <LwxButton variant="secondary" @click="router.push({ path: '/invoice/' + selectedPeriod + '/add-expense' })">
                Add Expense
            </LwxButton>

            <div class="col-span-2 mt-2" v-if="invoices && invoices[selectedPeriod] && invoices[selectedPeriod].shifts && ['open', 'declined'].includes(selectedStatus)">
                <LwxButton @click="attemptSubmit()">
                    Submit Invoice
                </LwxButton>
            </div>

        </div>

    </div>

</template>

<script setup>

    import { LockClosedIcon } from '@heroicons/vue/20/solid'

    import { reactive, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import { util } from '@/Helpers'

    import { useConfigStore, useNotificationStore, useUserStore, useInvoiceStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'

    const configStore = useConfigStore()
    const notificationStore = useNotificationStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()

    const config = configStore.config
    const { user } = storeToRefs(userStore)
    const { invoices, selectedPeriod, selectedStatus, careNotesMissing } = storeToRefs(invoiceStore)

    const router = useRouter()
    const route = useRoute()

    const emit = defineEmits(['saveData'])

    const props = defineProps({
        userProfile: {
        },
        currentPeriod: {
        },
        selectedPeriod: {
        }
    })

    const state = reactive({
        selectedPeriodId: props.selectedPeriod
    })

    function attemptSubmit() {

        if (invoices.value[selectedPeriod.value].shifts.length == 0 && invoices.value[selectedPeriod.value].trips.length == 0 && invoices.value[selectedPeriod.value].expenses.length == 0 && !invoices.value[selectedPeriod.value].notes) {
            notificationStore.add('Error', 'error', 'No shifts, trips, expenses, or notes to submit')
            return false;
        }
        if (careNotesMissing.value) {
            notificationStore.add('Warning', 'error', 'One or more shifts are missing the required care notes')
        }

        router.push({ path: '/invoice/' + selectedPeriod.value + '/certify' })

    }

    watch(
        () => route.fullPath,
        async () => {
            if (route.params.period) {
                state.selectedPeriodId = route.params.period;
            } else {
                state.selectedPeriodId = props.currentPeriod;
            }
        }
    )

</script>
