<template>

    <div>
        <div class="fixed w-full z-50">
            <NavBar subtitle="Caregiver Portal" />
        </div>

        <div class="w-full pt-24 mx-auto px-4 sm:px-6 md:px-0 z-10">
            <div class="mx-auto sm:w-full md:max-w-3xl bg-white shadow-m">

                <RouterView :currentPeriod="config.currentPeriod.id" :userProfile="user.profile" @save-data="preSaveData" @delete-data="deleteData" @error="errorMessage" />

                <footer class="text-xs font-semibold tracking-wider leading-snug text-center text-gray-400 py-10">
                    &copy; {{ today.getFullYear() }} LifeWorx, Inc.
                    <br class="inline-block md:hidden" />
                    All Rights Reserved.
                </footer>

            </div>
        </div>

        <Notification />
        <OverlaySubmit />
        <OverlayConfirm />

    </div>

</template>

<script setup>

    import { watch } from 'vue'
    import { RouterView } from 'vue-router'
    import { storeToRefs } from 'pinia'

    import { useConfigStore, useAuthStore, useNotificationStore, useUserStore, useInvoiceStore, useShiftStore, useTripStore, useExpenseStore } from '@/Stores'

    import NavBar from '@/Components/NavBar.vue'
    import Notification from '@/Components/Notification.vue'
    import OverlaySubmit from '@/Components/OverlaySubmit.vue'
    import OverlayConfirm from '@/Components/OverlayConfirm.vue'

    import { util } from '@/Helpers'

    const today = new Date()
    const mountEl = document.querySelector("#app")

    const configStore = useConfigStore()
    const authStore = useAuthStore()
    const notificationStore = useNotificationStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()
    const shiftStore = useShiftStore()
    const tripStore = useTripStore()
    const expenseStore = useExpenseStore()

    const { config } = storeToRefs(configStore)
    const { showOverlayConfirm, overlayConfirmData, overlayConfirmed, overlayConfirmedUnwatch, showOverlaySubmit, overlaySubmitAmount, showNotification, notificationMessage } = storeToRefs(notificationStore)
    const { user } = storeToRefs(userStore)
    const { invoices } = storeToRefs(invoiceStore)

    config.value = JSON.parse(mountEl.dataset.config)
    const apiVersion = import.meta.env.VITE_API_VERSION;


    function preSaveData(data) {

        // If the current period's invoice object does not have an invoice_id, create it
        if (!invoices.value[data.period].id) {
            invoiceStore.store().then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    invoices.value[data.period] = response.data
                    saveData(data)
                }
            }).catch((err) => {
                authStore.handleError(err.error)
            })
        } else {
            saveData(data)
        }

    }

    function saveData(data) {

        // Add or Update Shift
        if (data.type == 'shift') {
            if (!invoices.value[data.period].shifts) {
                invoices.value[data.period].shifts = []
            }
            const payload = {
                client_id: data.data.client.id,
                rate_id: data.data.rate.id,
                shift: {
                    start: util.formatDate(config.value.dateTimeFormat, util.getDateFromString(data.data.shift.start)),
                    end: util.formatDate(config.value.dateTimeFormat, util.getDateFromString(data.data.shift.end))
                }
            }

            if (data.data.careNotes) {

                if (apiVersion === 'v1') {
                    payload.care_notes = JSON.parse(JSON.stringify(data.data.careNotes))
                }

                if (apiVersion !== 'v1') {
                    payload.carenote_definition = data.data.carenote_definition
                    payload.care_note = JSON.parse(JSON.stringify(data.data.careNotes))
                }
            }

            if (!data.id) {

                // Add New Shift

                shiftStore.store(invoices.value[data.period].id, payload).then((response) => {

                    if (response.status >= 200 && response.status < 400) {
                        // TODO: Switch to UTC here
                        // response.data.start = util.makeLocalTime(new Date(response.data.start))
                        // response.data.end = util.makeLocalTime(new Date(response.data.end))
                        invoices.value[data.period].shifts.push(response.data)
                        invoiceStore.sortShifts()
                        notificationStore.add('New shift saved')
                    }

                }).catch((err) => {
                    authStore.handleError(err.error)
                })

            } else {

                // Update Existing Shift

                const index = invoices.value[data.period].shifts.findIndex(item => item.id === data.id)

                shiftStore.update(invoices.value[data.period].id, data.id, payload).then((response) => {

                    if (response.status >= 200 && response.status < 400) {
                        invoices.value[data.period].shifts[index] = response.data
                        invoiceStore.sortShifts()
                        notificationStore.add('Shift updated')
                    }

                }).catch((err) => {
                    authStore.handleError(err.error)
                })

            }
        }

        // Add or Update Trip
        if (data.type == 'trip') {
            if (!invoices.value[data.period].trips) {
                invoices.value[data.period].trips = []
            }
            const payload = {
                client_id: data.data.client.id,
                distance: data.data.distance,
                memo: data.data.memo
            }

            if (!data.id) {

                // Add New Trip

                tripStore.store(invoices.value[data.period].id, payload).then((response) => {

                    if (response.status >= 200 && response.status < 400) {
                        invoices.value[data.period].trips.push(response.data)
                        notificationStore.add('New trip saved')
                    }

                }).catch((err) => {
                    authStore.handleError(err.error)
                })

            } else {

                // Update Existing Trip

                const index = invoices.value[data.period].trips.findIndex(item => item.id === data.id)

                tripStore.update(invoices.value[data.period].id, data.id, payload).then((response) => {

                    if (response.status >= 200 && response.status < 400) {
                        invoices.value[data.period].trips[index].client_id = data.data.client.id
                        invoices.value[data.period].trips[index].distance = data.data.distance
                        invoices.value[data.period].trips[index].memo = util.escapeString(data.data.memo)
                        notificationStore.add('Trip updated')
                    }

                }).catch((err) => {
                    authStore.handleError(err.error)
                })

            }

        }

        // Add or Update Expense
        if (data.type == 'expense') {
            if (!invoices.value[data.period].expenses) {
                invoices.value[data.period].expenses = []
            }
            const payload = {
                client_id: data.data.client.id,
                amount: data.data.amount,
                memo: data.data.memo
            }

            if (!data.id) {

                // Add New Expense

                expenseStore.store(invoices.value[data.period].id, payload).then((response) => {

                    if (response.status >= 200 && response.status < 400) {
                        invoices.value[data.period].expenses.push(response.data)
                        notificationStore.add('New expense saved')
                    }

                }).catch((err) => {
                    authStore.handleError(err.error)
                })

            } else {

                // Update Existing Expense

                const index = invoices.value[data.period].expenses.findIndex(item => item.id === data.id)

                expenseStore.update(invoices.value[data.period].id, data.id, payload).then((response) => {

                    if (response.status >= 200 && response.status < 400) {
                        invoices.value[data.period].expenses[index].client_id = data.data.client.id
                        invoices.value[data.period].expenses[index].amount = data.data.amount
                        invoices.value[data.period].expenses[index].memo = util.escapeString(data.data.memo)
                        notificationStore.add('Expense updated')
                    }

                }).catch((err) => {
                    authStore.handleError(err.error)
                })

            }

        }

        // Add or Update Invoice Note
        if (data.type == 'notes') {

            const payload = {
                notes: data.data.notes
            }

            invoiceStore.update(invoices.value[data.period].id, payload).then((response) => {

                if (response.status >= 200 && response.status < 400) {
                    invoices.value[data.period].notes = util.escapeString(data.data.notes)
                    notificationStore.add('Notes updated')
                }

            }).catch((err) => {
                authStore.handleError(err.error)
            })

        }

        // Certify Invoice
        if (data.type == 'certification') {

            const payload = {
                certification: {
                    submitted: data.data.submitted,
                    signature_image: data.data.signature_image
                }
            }

            let preSubmitStatus = invoices.value[data.period].status;
            invoices.value[data.period].status = 'submitted'

            invoiceStore.update(invoices.value[data.period].id, payload).then((response) => {


                if (response.status >= 200 && response.status < 400) {
                    invoices.value[data.period].signature = response.data.signature
                    invoices.value[data.period].submitted = response.data.submitted
                    invoices.value[data.period].status = response.data.status
                    overlaySubmitAmount.value = util.currency(util.invoiceTotal(invoices.value[data.period], config.value, user.value.rates))
                    showOverlaySubmit.value = true
                }

            }).catch((err) => {

                invoices.value[data.period].status = preSubmitStatus

                if (err.error.response.data.shift && Object.keys(err.error.response.data.shift).length > 0) {

                    // There are shift-specific error messages, let's show a dialog

                    let formattedResponse = {
                        message: []
                    }

                    let shiftsObj = util.convertArrayToObject(invoices.value[data.period].shifts, 'id')

                    err.error.response.data.shift.forEach(shift => {
                        formattedResponse.message.push('A shift for ' + user.value.clients[shiftsObj[shift.id].client_id].first_name + ' ' + user.value.clients[shiftsObj[shift.id].client_id].last_name + ' is ' + shift.message.toLowerCase() + '.')
                    });
                    formattedResponse.message.push('Please review your invoice before submitting again.');

                    overlayConfirmData.value = formattedResponse
                    showOverlayConfirm.value = true

                    overlayConfirmedUnwatch.value = watch(
                        () => overlayConfirmed.value,
                        async () => {
                            authStore.auth.loading = false
                            notificationStore.overlayConfirmedUnwatch()
                            overlayConfirmed.value = false
                        }
                    )

                } else {
                    authStore.handleError(err.error)
                }
            })

        }

    }

    function deleteData(data) {

        // Delete Shift
        if (data.type == 'shift') {

            shiftStore.destroy(invoices.value[data.period].id, data.id).then((response) => {

                if (response.status >= 200 && response.status < 400) {
                    invoices.value[data.period].shifts.splice(invoices.value[data.period].shifts.findIndex(item => item.id === data.id), 1)
                    notificationStore.add('Shift deleted')
                }

            }).catch((err) => {
                authStore.handleError(err.error)
            })

        }

        // Delete Trip
        if (data.type == 'trip') {

            tripStore.destroy(invoices.value[data.period].id, data.id).then((response) => {

                if (response.status >= 200 && response.status < 400) {
                    invoices.value[data.period].trips.splice(invoices.value[data.period].trips.findIndex(item => item.id === data.id), 1)
                    notificationStore.add('Trip deleted')
                }

            }).catch((err) => {
                authStore.handleError(err.error)
            })

        }

        // Delete Expense
        if (data.type == 'expense') {

            expenseStore.destroy(invoices.value[data.period].id, data.id).then((response) => {

                if (response.status >= 200 && response.status < 400) {
                    invoices.value[data.period].expenses.splice(invoices.value[data.period].expenses.findIndex(item => item.id === data.id), 1)
                    notificationStore.add('Expense deleted')
                }

            }).catch((err) => {
                authStore.handleError(err.error)
            })

        }

        // Delete Notes
        if (data.type == 'notes') {

            const payload = {
                notes: ''
            }

            invoiceStore.update(invoices.value[data.period].id, payload).then((response) => {

                if (response.status >= 200 && response.status < 400) {
                    invoices.value[data.period].notes = ''
                    notificationStore.add('Notes deleted')
                }

            }).catch((err) => {
                authStore.handleError(err.error)
            })

        }

    }

    function errorMessage(data) {
        notificationStore.add('Error ' + data.code + ':', 'error', data.type)
    }

</script>
