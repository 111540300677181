import { onBeforeUnmount, reactive } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import * as Sentry from "@sentry/vue";

import { defineStore, storeToRefs, acceptHMRUpdate } from 'pinia'

import { useConfigStore, useUserStore, useInvoiceStore, useNotificationStore } from '@/Stores'

import { util } from '@/Helpers'

export const useAuthStore = defineStore('auth', () => {

    const router = useRouter()
    const route = useRoute()

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()
    const notificationStore = useNotificationStore()

    const { user } = storeToRefs(userStore)
    const { invoices } = storeToRefs(invoiceStore)

    const auth = reactive({
        loading: false,
        id: null,
        authenticated: false,
        verifyDataLoading: false,
        error: null,
        message: null
    })


    //
    // Reactive handler for nav bar loading indicator
    //
    const loading = auth.loading

    //
    // Auth bootstrapping
    //
    checkAuth()

    //
    // Page load auth check handler
    //
    async function checkAuth(redirect = true) {

        if (localStorage.getItem('AUTH_STATE')) {

            auth.loading = true
            auth.verifyDataLoading = true

            const match = window.location.href.match(/\/invoice\/(\d+)/);
            const params = match ? { params: {period_id : match[1]}} : {};
            await axios.get('/users', params).then(response => {

                auth.loading = false
                auth.verifyDataLoading = false

                setup(response)

                if (response.data) {
                    updateState(response.data.profile)
                }

                if (redirect && auth.authenticated == false) {
                    router.push(route.query.returnUrl || '/')
                } else if (auth.authenticated == true && route.query.returnUrl == '/') {
                    router.push(route.query.returnUrl)
                }

            }).then(response =>{

                if (auth.authenticated == true && route.query.returnUrl) {
                    router.push(route.query.returnUrl)
                }

            }).catch((err) => {

                auth.error = 'Session Expired'
                auth.loading = false
                auth.verifyDataLoading = false
                handleError(err)

            })

        }

    }


    //
    // Helpers
    //

    // Refresh login state in local storage
    function updateState(payload) {
        auth.id = payload.id
        auth.authenticated = true
        localStorage.removeItem('AUTH_STATE')
        localStorage.setItem('AUTH_STATE', JSON.stringify({ id: payload.id, authenticated: true }))
    }

    function handleError(err) {

        if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            if (!err.response.ok) {

                notificationStore.add('Error ' + err.response.status, 'error', err.response.data.message)

                if ([401, 403].includes(err.response.status)) {
                    // auto-logout if 401 Unauthorized or 403 Forbidden response returned from api
                    localStorage.removeItem('AUTH_STATE')
                    auth.authenticated = false
                    router.push('/login')
                }

                if ([401].includes(err.response.status) === false) {
                    Sentry.captureException(err)
                }
            }

        } else if (err.request) {
            // The request was made but no response was received
            Sentry.captureException(err)
            notificationStore.add('Error', 'error', util.titleCase(err.message))
        } else {
            // Something happened in setting up the request that triggered an Error
            Sentry.captureException(err)
            notificationStore.add('Error', 'error', util.titleCase(err.message))
        }

    }

    // Process user data after login or auth check, populate local data stores
    function setup(response) {

        user.value.profile = response.data.profile

        user.value.recruiter = response.data.recruiter

        // Set up clients
        user.value.clients = util.convertArrayToObject(response.data.clients, 'id')

        // Set up rates
        let rates = []
        response.data.clients.forEach(client => {
            rates.push(...client.rates)
        })
        user.value.rates = util.convertArrayToObject(rates, 'id')

        if (Object.keys(response.data.invoices).length > 1) {

            response.data.invoices.forEach(invoice => {
                invoice.shifts.forEach(shift => {
                    // TODO: Switch to UTC here
                })
                invoice.shifts.sort(util.shiftCompare)
            })

        }

        // Set up invoices
        invoiceStore.storeData(response.data.invoices);

        // If an invoice for the current period doesn't exist, create a placeholder
        if (!invoices.value[configStore.config.currentPeriod.id]) {
            invoices.value[configStore.config.currentPeriod.id] = { ...configStore.config.currentPeriod }
            invoices.value[configStore.config.currentPeriod.id].id = null
            invoices.value[configStore.config.currentPeriod.id].shifts = []
            invoices.value[configStore.config.currentPeriod.id].trips = []
            invoices.value[configStore.config.currentPeriod.id].expenses = []
            // invoices.value[configStore.config.currentPeriod.id].status = 'open'
            invoices.value[configStore.config.currentPeriod.id].period_id = configStore.config.currentPeriod.id
        }

    }

    // Refresh user data every 5 minutes
    const refreshTimer = setInterval(refreshData, 300000)

    function refreshData() {
        checkAuth(false)
    }

    // Cleanup
    onBeforeUnmount(() => {
        clearInterval(refreshTimer)
    })


    //
    // Auth action handlers
    //

    async function login(username, password) {

        auth.loading = true

        await axios.get('/sanctum/csrf-cookie').then(response => {

            axios.post(
                '/sessions',
                {
                    username,
                    password
                },
            ).then(response => {

                setup(response)
                updateState(response.data.profile)

            }).then(response => {

                router.push(route.query.returnUrl || '/')
                auth.loading = false

            })
            .catch((err) => {

                Sentry.captureException(err)

                auth.loading = false
                auth.error = err.response.data.message
                router.push('/login')

            })

        }).catch((err) => {

            Sentry.captureException(err)

            auth.loading = false
            auth.error = err
            notificationStore.add('Error ' + err.response.status, 'error', err.response.data.message)
            router.push('/login')

        })

    }

    async function logout() {

        localStorage.clear()

        auth.loading = true

        axios.delete('/sessions').then(response => {

            auth.loading = false
            auth.id = null
            auth.authenticated = false
            auth.message = response.data.message
            localStorage.removeItem('auth')
            router.push('/login')

        }).catch((err) => {

            Sentry.captureException(err)

            auth.loading = false
            router.push('/login')

        })

    }

    return { auth, login, logout, loading, handleError }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
