<template>

    <TransitionRoot as="template" :show="showOverlaySubmit">
        <Dialog as="div" class="relative z-10" @close="close()">

            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-lifeworx-green-600 bg-opacity-90 backdrop-blur-sm transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 z-10">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <div>
                                <img class="block h-48 w-auto mx-auto" src="@/../assets/piggy-bank.svg" alt="Piggy Bank Payout" />
                                <div class="mt-3 text-center sm:mt-5">

                                    <DialogTitle as="h3" class="text-6xl font-cursive font-extrabold leading-10 text-white">
                                        Great work!</DialogTitle>
                                    <div class="mt-6 font-semibold tracking-wide">
                                        <p class="text-xl text-white">Your payout <span v-if="overlaySubmitAmount">of {{ overlaySubmitAmount }} </span> should arrive in several days.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6">
                                <LwxButton @click="close()" variant="secondaryWhite">
                                    Close
                                </LwxButton>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>

        </Dialog>
    </TransitionRoot>

</template>

<script setup>

    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

    import { useNotificationStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'

    const notificationStore = useNotificationStore()

    const { showOverlaySubmit, overlaySubmitAmount } = storeToRefs(notificationStore)

    function close() {
        showOverlaySubmit.value = false
    }

</script>
