<template>

    <div class="">
        <button :type="this.type" :class="this.classes" :disabled="this.disabled">
            <slot name="icon-before"></slot>
            <slot></slot>
            <slot name="icon-after"></slot>
        </button>
    </div>

</template>

<script>

    export default {
        name: 'LwxButton',
        props: {
            variant: {
                type: String,
                default: "primary"
            },
            disabled: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: "button"
            }
        },
        data() {
            return {
                baseClasses: 'w-full h-12 md:h-16 flex items-center justify-center flex-1 px-1.5 md:px-2.5 py-7 md:py-2.5 rounded text-base font-bold tracking-wider text-center uppercase',
                themeClasses: {
                    primary: 'bg-lifeworx-blue-500 text-white enabled:hover:bg-lifeworx-blue-600 disabled:bg-gray-300',
                    secondary: 'text-lifeworx-blue-500 border-2 border-lifeworx-blue-500 enabled:hover:bg-lifeworx-blue-500 enabled:hover:border-lifeworx-blue-500 enabled:hover:text-white disabled:border-gray-300 disabled:text-gray-300',
                    primaryRed: 'bg-lifeworx-red-500 text-white hover:bg-lifeworx-red-600',
                    primaryWhite: 'bg-white text-lifeworx-red-500 hover:hover:bg-white/75',
                    secondaryRed: 'text-lifeworx-red-500 border-2 border-lifeworx-red-500 hover:bg-lifeworx-red-500 hover:text-white',
                    secondaryWhite: 'text-white border-2 border-white hover:bg-white/25 hover:text-white',
                    disabled: 'text-white bg-gray-300 cursor-default'
                }
            }
        },
        computed: {
            classes: function() {
                return this.baseClasses + ' ' + this.themeClasses[this.$props["variant"]];
            }
        },
        watch:{
        },
        updated(){
        },
        mounted(){
        },
        created() {
        },
        methods:{
        }
    }


</script>
