
import { ref, onMounted } from 'vue'

import { defineStore, acceptHMRUpdate, storeToRefs } from 'pinia'

import { useAuthStore } from '@/Stores'

export const useTripStore = defineStore('trip', () => {

    const authStore = useAuthStore()
    const { loading } = storeToRefs(authStore)

    const trips = ref({})


    //
    // API
    //

    async function index() {

        authStore.auth.loading = true

        return await axios.get('/invoices/').then(response => {
            authStore.auth.loading = false
        }).catch((err) => {
            authStore.auth.loading = false
            authStore.handleError(err)
        });
    }

    async function show(invoice_id, trip_id) {

        authStore.auth.loading = true

        return await axios.get('/invoices/' + invoice_id + '/trips/' + trip_id).then(response => {
            authStore.auth.loading = false
        }).catch((err) => {
            authStore.auth.loading = false
            authStore.handleError(err)
        });
    }

    async function store(invoice_id, payload) {

        authStore.auth.loading = true

        return await new Promise((resolve, reject) => {
            axios.post('/invoices/' + invoice_id + '/trips', payload)
            .then(function (response) {
                // Handle redirect here
                if (response.status === 201) {
                    const redirectUrl = response.headers.location;
                    return axios.get(redirectUrl);
                }
            }).then(function (response) {
                authStore.auth.loading = false
                resolve(response)
            }).catch(function (error) {
                authStore.auth.loading = false
                reject({ "error": error })
            })
        });

    }

    async function update(invoice_id, trip_id, payload) {

        authStore.auth.loading = true

        return await new Promise((resolve, reject) => {
            axios.patch('/invoices/' + invoice_id + '/trips/' + trip_id, payload)
            .then(function (response) {
                authStore.auth.loading = false
                resolve(response)
            }).catch(function (error) {
                authStore.auth.loading = false
                reject({ "error": error })
            })
        });

    }

    async function destroy(invoice_id, trip_id) {

        authStore.auth.loading = true

        return await new Promise((resolve, reject) => {
            axios.delete('/invoices/' + invoice_id + '/trips/' + trip_id)
            .then(function (response) {
                authStore.auth.loading = false
                resolve(response)
            }).catch(function (error) {
                authStore.auth.loading = false
                reject({ "error": error })
            })
        });

    }

    onMounted(() => {
    })

    return { trips, index, show, store, update, destroy }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useTripStore, import.meta.hot))
}
