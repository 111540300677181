<template>

    <LwxBreadcrumb :pages="[{ name: invoices[invoiceStore.selected] && invoices[ invoiceStore.selected ].notes ? 'Edit Notes' : 'Add Notes', href: '/invoice/' + invoiceStore.selected + '/add-notes', current: true }]"></LwxBreadcrumb>

    <div class="w-full mx-auto pt-2">

        <div>
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">{{ invoices[invoiceStore.selected] && invoices[invoiceStore.selected].notes > 0 ? 'Edit Notes' : 'Add Notes' }}</h1>

            <div class="grid gap-4 grid-cols-1 w-full md:w-2/3 mx-auto pt-2 ">

                <textarea rows="10" name="notesField" id="notesField" ref="notesField" v-model="notesData" class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-600 sm:py-4 sm:text-md sm:leading-6" placeholder="Enter any comments"></textarea>

            </div>
        </div>

    </div>

    <div class="w-full md:w-1/2 mx-auto pt-4">

        <LwxButton @click="saveNotes()" :disabled="!notesData">
            Save Notes
        </LwxButton>

        <LwxHr class="my-8 md:my-6" />

        <div class="grid gap-4 pt-2">

        <LwxButton @click="router.push({ path: '/invoice/' + invoiceStore.selected })" variant="secondaryRed">
            {{ state.editing ? 'Cancel Edits' : 'Cancel' }}
        </LwxButton>

        <LwxButton v-if="state.editing" @click="deleteNotes()" variant="secondaryRed">
            Delete Notes
        </LwxButton>

        </div>

    </div>

</template>

<script setup>

    import { ref, reactive, onMounted } from 'vue'

    import { useConfigStore, useUserStore, useInvoiceStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { util } from '@/Helpers'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'
    import LwxBreadcrumb from '@/Components/LwxBreadcrumb.vue'

    import { useRouter } from 'vue-router'

    const router = useRouter()

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()

    const config = configStore.config
    const { user } = storeToRefs(userStore)
    const { invoices, selectedPeriod } = storeToRefs(invoiceStore)

    const emit = defineEmits(['saveData', 'deleteData', 'error'])

    const props = defineProps({
        period: {
            type: String
        },
        userProfile: {
            type: Object
        },
        currentPeriod: {
            type: Number
        }
    })

    const state = reactive({
        editing: false
    })

    const notesField = ref(null)
    const notesData = ref()

    onMounted(() => {
        if (invoices.value && invoices.value[invoiceStore.selected] && invoices.value[invoiceStore.selected].notes) {
            notesData.value = util.decodeString(invoices.value[invoiceStore.selected].notes)
            state.editing = true
        }
    })


    function saveNotes() {

        emit('saveData', {
            type: 'notes',
            period: invoiceStore.selected,
            data: {
                notes: notesData.value
            }
        })

        router.push({ path: '/invoice/' + invoiceStore.selected })

    }

    function deleteNotes() {

        emit('deleteData', {
            type: 'notes',
            period: invoiceStore.selected
        })

        router.push({ path: '/invoice/' + invoiceStore.selected })

    }

</script>
